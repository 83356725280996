<template>
	<div class="row map-overlay">
		<div class="col">
			<div class="card border-light mb-3">
				<div class="card-body">
					<data-filter
						display
						get-by="name"
						:form-id="formId"
						@submit="emitValues"
						@clear="clearFilter">
						<template slot="form">
							<div class="row">
								<div :class="dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col' ">
									<div class="form-group">
										<label
											for="dateRange"
											class="label">
											{{ translate('date_range') }}
										</label>
										<select
											v-model="dateRange"
											name="dateRange"
											class="form-control">
											<option
												v-for="range in dateRanges"
												:key="range"
												:value="range">
												{{ translate(range) }}
											</option>
										</select>
									</div>
								</div>
								<div
									v-if="dateRange === dateRanges.custom"
									class="col-md-4 col-sm-6 col-xs-12">
									<div class="form-group">
										<label
											for="startDate"
											class="label">
											{{ translate('start_date') }}
										</label>
										<v-date-picker
											id="startDate"
											v-model="startDate"
											:input-props="{ class: 'form-control' }"
											:formats="calendarConfig"
											name="startDate"
											show-caps />
									</div>
								</div>
								<div
									v-if="dateRange === dateRanges.custom"
									class="col-md-4 col-sm-6 col-xs-12">
									<div class="form-group">
										<label
											for="endDate"
											class="label">
											{{ translate('end_date') }}
										</label>
										<v-date-picker
											id="endDate"
											v-model="endDate"
											:input-props="{ class: 'form-control' }"
											:formats="calendarConfig"
											name="endDate"
											show-caps />
									</div>
								</div>
							</div>
						</template>
					</data-filter>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import DataFilter from '@/components/DataFilter';
import { Grids } from '@/translations';
import {
	custom,
	DATE_END_FILTER,
	DATE_START_FILTER,
	DATE_RANGE_FILTER,
	DATE_RANGES,
	V_CALENDAR_CONFIG as calendarConfig,
	YMD_FORMAT,
} from '@/settings/Dates';

export default {
	name: 'MapFilters',
	messages: [Grids],
	components: {
		DataFilter,
	},
	props: {
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			filters: {},
			dateRange: DATE_RANGES.this_month,
			startDate: null,
			endDate: null,
			calendarConfig,
			dateRanges: { ...DATE_RANGES, custom },
		};
	},
	computed: {
		formId() {
			const suffix = this.type !== '' ? `-${this.type}` : '';
			return `filter-form${suffix}`;
		},
	},
	watch: {
		startDate(newVal) {
			try {
				this.filters[DATE_START_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_START_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_START_FILTER] = null;
			}
		},
		endDate(newVal) {
			try {
				this.filters[DATE_END_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_END_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_END_FILTER] = null;
			}
		},
		dateRange(newVal) {
			this.filters[DATE_RANGE_FILTER] = newVal;
			if (newVal !== custom) {
				this.filters[DATE_START_FILTER] = null;
				this.filters[DATE_END_FILTER] = null;
			}
		},
	},
	methods: {
		emitValues() {
			this.$emit('submit', this.filters);
		},
		clearFilter() {
			this.$emit('clear');

			this.filters = {};
			this.dateRange = DATE_RANGES.this_month;
			this.startDate = null;
			this.endDate = null;
		},
	},
};
</script>
<style>
.map-overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
}
</style>
