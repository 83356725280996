/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { api, getURLParams } from '@/config/axios';
import Errors from '@/util/Errors';
import { RESPONSE_TREE as responseTree } from '@/settings/RequestReply';

function isObject(value) {
	return typeof value === 'object' && value !== null && !Array.isArray(value);
}

class AxiosRequest {
	constructor(initInstance) {
		let instance = initInstance;
		if (typeof instance === 'undefined') {
			instance = api;
		}
		this.instance = instance;
		this.loading = false;
		this.response = {};
		/** @type {{ count: number, current_page: number, per_page: number, total: number, total_pages: number }} */
		this.pagination = {};
		this.errors = new Errors();
	}

	clear() {
		this.loading = false;
		this.response = {};
		this.pagination = {};
		this.errors.clear();
	}

	get(endpoint, options = {}, settings) {
		this.loading = true;
		const urlParameters = AxiosRequest.getUrlFilters();
		if (typeof endpoint !== 'string' || endpoint === '') {
			return Promise.reject(new Error(new Error('Invalid Endpoint')));
		}
		const query = { ...AxiosRequest.queryStringToObject(urlParameters.filters), ...options };
		const filters = AxiosRequest.setFilters(query);

		const newUrl = urlParameters.absoluteUrl + filters;
		window.history.pushState({}, '', newUrl);
		return this.instance.get(endpoint + getURLParams(options), settings).then((response) => {
			this.response = response;
			return Promise.resolve(this.getData());
		}).catch((error) => {
			this.response = error;
			this.errors.record(error);
			return Promise.reject(this.errors);
		}).finally(() => {
			this.loading = false;
		});
	}

	getData() {
		let response = [];
		if (Object.keys(this.response)) {
			if (typeof this.response.data !== 'undefined') {
				response = this.response.data;
				if (typeof response[responseTree] !== 'undefined') {
					response = response.data;
				}
				this.pagination = this.getPagination();
			}
		}
		return response;
	}

	getPagination() {
		let response = {};
		try {
			const { meta } = this.response[responseTree];
			if (typeof meta !== 'undefined' && typeof meta.pagination !== 'undefined') {
				response = meta.pagination;
			}
		} catch (error) {
			response = {};
		}
		return response;
	}

	static getUrlFilters() {
		const url = { absoluteUrl: '', filters: '' };
		const absoluteUrl = window.location.toString();
		const urlSplits = absoluteUrl.split('?');

		if (absoluteUrl.includes('?')) {
			[url.absoluteUrl, url.filters] = urlSplits;
			return url;
		}
		url.absoluteUrl = absoluteUrl;
		return url;
	}

	static setFilters(options) {
		if (!isObject(options)) {
			return '';
		}

		const parameters = [];

		Object.keys(options).filter((item) => {
			if (item === 'page') {
				const page = options[item];
				const pageNumber = parseInt(page, 10);
				if (pageNumber > 0) {
					parameters.push(`${item}=${pageNumber}`);
				}
			} else if (typeof options[item] === 'boolean') {
				parameters.push(`${item}=${options[item] ? 1 : 0}`);
			} else if (typeof options[item] !== 'undefined' && options[item] !== null) {
				parameters.push(`${item}=${options[item]}`);
			}
			return null;
		});

		return parameters.length ? `?${parameters.join('&')}` : '';
	}

	static queryStringToObject(queryString) {
		const params = new URLSearchParams(queryString);
		const query = {};
		params.forEach((value, key) => {
			query[key] = value;
		});
		return query;
	}
}

export { AxiosRequest };
export default AxiosRequest;
