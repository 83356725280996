<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row">
		<div class="col">
			<div class="card mb-0">
				<div class="bg-dark p-3">
					<div class="row mb-3">
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'd-flex justify-content-center' : ''"
							class="col d-flex align-items-center">
							<div class="text-light text-uppercase font-weight-bold">
								{{ title.length > 0 ? title : translate('replicated_site_links') }}
							</div>
							<b-button
								v-if="showEdit"
								variant="transparent"
								class="p-0 ml-2 text-white"
								@click="$router.push({ name: 'ReplicatedSite' })">
								<i class="fas fa-cog btn-primary p-1 rounded" />
							</b-button>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col d-flex align-items-center w-100">
							<b-dropdown
								variant="dark"
								block
								:class="['xs', 'sm'].includes(windowWidth) ? 'mx-auto mw-100' : 'w-100'"
								:style="['xs', 'sm'].includes(windowWidth) ? 'min-width: 85%;' : ''"
								class="border border-secondary rounded">
								<template slot="button-content">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'mx-2' : ''"
										class="h-100 d-inline-block text-left"
										:style="['xs','sm'].includes(windowWidth) ? 'width: 87%;' : 'width: 93%;'"
										style="height: 1.2em !important;">
										<div class="d-inline-block h-100 text-left">
											<div class="d-flex h-100 align-items-center mr-1">
												<i
													v-if="selectedLink.icon && !selectedLink.image"
													:class="selectedLink.icon"
													class="mr-2"
													style="margin-left: -7px;" />
												<div
													v-if="selectedLink.image"
													class="h-100 mr-2"
													:style="['xs','sm'].includes(windowWidth) ? 'width: 87%; margin-left: -7px;' : 'margin-left: -4px;'">
													<img
														:src="selectedLink.image"
														class="position-relative h-100"
														style="top: -1px;">
												</div>
											</div>
										</div>
										{{ translate( selectedLink.translation.key, translateParams(selectedLink.translation.params)) }}
									</div>
								</template>
								<b-dropdown-item
									v-for="(link, key) in replicatedLinks"
									:key="key"
									@click="selectedLinkKey = key">
									<div
										class="h-100 d-inline-block text-left"
										style="width: 93%; height: 1.2em !important;">
										<div class="d-inline-block h-100 text-left">
											<div class="d-flex h-100 align-items-center">
												<i
													v-if="link.icon && !link.image"
													:class="link.icon" />
												<div
													v-if="link.image"
													class="h-100 mx-1">
													<img
														:src="link.image"
														class="h-100 mr-2"
														style="margin-left: -10px">
												</div>
											</div>
										</div>
										{{ translate( link.translation.key, translateParams(link.translation.params)) }}
									</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'pl-0'"
							class="col-12 col-md-auto d-flex align-items-center">
							<copy-button
								:copy-content="selectedLink.url"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mx-auto' : ''"
								:style="['xs', 'sm'].includes(windowWidth) ? 'width: 85%;' : ''"
								icon-class="fa fa-link"
								:button-text="translate('copy_link')" />
						</div>
					</div>
					<div
						:class="['xs', 'sm'].includes(windowWidth) ? 'mx-auto' : ''"
						:style="['xs', 'sm'].includes(windowWidth) ? 'width: 85%;' : ''"
						class="row no-gutters">
						<div
							v-if="!['xs', 'sm'].includes(windowWidth)"
							class="col-auto pr-2">
							{{ translate('url') }}:
						</div>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'text-left'"
							class="col">
							<span v-if="['xs', 'sm'].includes(windowWidth)">
								{{ translate('url') }}:
							</span>
							<a
								:href="selectedLink.url"
								target="_blank"
								v-html="formattedUrl" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CopyButton from '@/components/CopyButton';
import { formatUrlWordBreaks } from '@/util/Urls';
import { Dashboard as DashboardTranslations, Profile, Products } from '@/translations';
import { LANDING_PAGES } from '@/settings/Replicated';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ReplicatedLinkSelector',
	messages: [DashboardTranslations, Profile, Products],
	components: { CopyButton },
	mixins: [WindowSizes],
	props: {
		links: {
			type: Object,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		showEdit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedLinkKey: Object.keys(this.links)[0],
		};
	},
	computed: {
		replicatedLinks() {
			try {
				return Object.fromEntries(Object.entries(this.links).map(([key, link]) => [
					key,
					{ ...LANDING_PAGES[key], url: link.url },
				]));
			} catch (error) {
				return {};
			}
		},
		selectedLink() {
			return this.replicatedLinks[this.selectedLinkKey];
		},
		formattedUrl() {
			return formatUrlWordBreaks(this.selectedLink.url);
		},
	},
	methods: {
		translateParams(params) {
			if (params) {
				return Object.fromEntries(Object.entries(params).map(([key, value]) => [key, this.translate(value)]));
			}
			return params;
		},
	},
};
</script>
