<template>
	<div
		v-if="!!Object.keys(periodInfoData).length || loading"
		class="row">
		<div
			class="col-md-4 col-xl-2 mb-4"
			:class="window.width >= 1200 && window.width <= 1440 ? 'col-xl-4' : 'col-xl-2'">
			<weekly-info
				v-if="!loading"
				:header-title="translate('total_earnings')"
				:show-abbreviation="true"
				:abbreviate-since="4"
				:small-label="translate('week_number_final', {number: periodInfoData.total_earnings.final.week})"
				:big-label="periodInfoData.total_earnings.final.amount_value.toString()"
				:big-formatted-label="periodInfoData.total_earnings.final.amount"
				:icon="`fas ${getCurrencyIcon(periodInfoData.total_earnings.final.currency_code)}`"
				:optional-label="'*'"
				:tooltip-label="translate('notice_final_earnings')" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div
			class="col-md-4 col-xl-2 mb-4"
			:class="window.width >= 1200 && window.width <= 1440 ? 'col-xl-4' : 'col-xl-2'">
			<weekly-info
				v-if="!loading"
				:header-title="translate('total_earnings')"
				:show-abbreviation="true"
				:abbreviate-since="4"
				:small-label="translate('week_number_est', {number: periodInfoData.total_earnings.estimated.week})"
				:big-label="periodInfoData.total_earnings.estimated.amount_value.toString()"
				:big-formatted-label="periodInfoData.total_earnings.estimated.amount"
				:icon="`fas ${getCurrencyIcon(periodInfoData.total_earnings.estimated.currency_code)}`"
				:optional-label="'*'"
				:tooltip-label="translate('notice_estimated_earnings')" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div
			class="col-md-4 col-xl-2 mb-4"
			:class="window.width >= 1200 && window.width <= 1440 ? 'col-xl-4' : 'col-xl-2'">
			<weekly-info
				v-if="!loading"
				:header-title="translate('total_earnings')"
				:show-abbreviation="true"
				:abbreviate-since="4"
				:small-label="translate('week_number_in_prog', {number: periodInfoData.total_earnings.in_progress.week})"
				:big-label="periodInfoData.total_earnings.in_progress.amount_value.toString()"
				:big-formatted-label="periodInfoData.total_earnings.in_progress.amount"
				:icon="`fas ${getCurrencyIcon(periodInfoData.total_earnings.in_progress.currency_code)}`"
				:optional-label="'*'"
				:tooltip-label="translate('notice_in_progress_earnings')" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div
			class="col-md-4 col-xl-2 mb-4"
			:class="window.width >= 1200 && window.width <= 1440 ? 'col-xl-4' : 'col-xl-2'">
			<weekly-info
				v-if="!loading"
				:header-title="translate('bv_left')"
				:show-abbreviation="true"
				:abbreviate-since="7"
				:small-label="translate('week_number', {number: periodInfoData.bv_left.week})"
				:big-label="periodInfoData.bv_left.amount_value.toString()"
				:big-formatted-label="periodInfoData.options.calculating_cycles ? translate('calculating') : periodInfoData.bv_left.amount"
				:big-label-description="translate('calculating_message')"
				:icon="'fas fa-arrow-left'"
				:hide-small-label="periodInfoData.options.calculating_cycles"
				:optional-label="translate('includes_carryover')"
				:tooltip-label="translate('notice_change_bvs')" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div
			class="col-md-4 col-xl-2 mb-4"
			:class="window.width >= 1200 && window.width <= 1440 ? 'col-xl-4' : 'col-xl-2'">
			<weekly-info
				v-if="!loading"
				:header-title="translate('bv_right')"
				:show-abbreviation="true"
				:abbreviate-since="7"
				:small-label="translate('week_number', {number: periodInfoData.bv_right.week})"
				:big-label="periodInfoData.bv_right.amount_value.toString()"
				:big-formatted-label="periodInfoData.options.calculating_cycles ? translate('calculating') : periodInfoData.bv_right.amount"
				:big-label-description="translate('calculating_message')"
				:icon="'fas fa-arrow-right'"
				:hide-small-label="periodInfoData.options.calculating_cycles"
				:optional-label="translate('includes_carryover')"
				:tooltip-label="translate('notice_change_bvs')" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div
			class="col-md-4 col-xl-2 mb-4"
			:class="window.width >= 1200 && window.width <= 1440 ? 'col-xl-4' : 'col-xl-2'">
			<weekly-info
				v-if="!loading"
				:header-title="translate('weekly_cyles')"
				:header-indicator="appliesForExcessCycles ? `${periodInfoData.cycles.current_week}/${periodInfoData.cycles.total_weeks}` : ''"
				:show-abbreviation="false"
				:small-label="translate('week_number', {number: periodInfoData.cycles.week})"
				:big-label="appliesForExcessCycles ? periodInfoData.cycles.amount : periodInfoData.cycles.amount_value.toString()"
				:big-formatted-label="periodInfoData.options.calculating_cycles ? translate('calculating') : (appliesForExcessCycles ? periodInfoData.cycles.excess_cycles.toString() : periodInfoData.cycles.amount)"
				:big-label-description="translate('calculating_message')"
				:abbreviation-without-format="appliesForExcessCycles"
				:icon="'fas fa-redo-alt'"
				:hide-small-label="periodInfoData.options.calculating_cycles"
				:optional-label="appliesForExcessCycles ? `* ${translate('excess_cycles')}` : '*'"
				:tooltip-label="translate('notice_change_cycles')" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import Dashboard from '@/util/Dashboard';
import { MDY_FORMAT } from '@/settings/Dates';
import { CURRENCY_ICONS, DEFAULT_CURRENCY_ICON } from '@/settings/Icons';
import WindowResolution from '@/mixins/WindowResolution';
import WeeklyInfo from '@/components/Cards/CardWidgetRegular';

export default {
	name: 'DistributorPeriodInfo',
	messages: [DashboardTranslations],
	components: {
		WeeklyInfo,
	},
	mixins: [WindowResolution],
	data() {
		return {
			periodInfo: new Dashboard(),
			rangeStart: '',
			rangeEnd: '',
			currencyIcons: CURRENCY_ICONS,
			defaultCurrencyIcon: DEFAULT_CURRENCY_ICON,
		};
	},
	computed: {
		periodInfoData() {
			try {
				const { response } = this.periodInfo.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		appliesForExcessCycles() {
			try {
				return this.periodInfoData.cycles.excess_cycles !== null && typeof this.periodInfoData.cycles.excess_cycles !== 'undefined';
			} catch (error) {
				return false;
			}
		},
		loading() {
			return !!this.periodInfo.data.loading;
		},
	},
	watch: {
		language() {
			this.$parent.rangeStart = this.$moment(this.rangeStart).format(MDY_FORMAT);
			this.$parent.rangeEnd = this.$moment(this.rangeEnd).format(MDY_FORMAT);
		},
		rangeStart() {
			this.$parent.rangeStart = this.$moment(this.rangeStart).format(MDY_FORMAT);
			this.$parent.rangeEnd = this.$moment(this.rangeEnd).format(MDY_FORMAT);
		},
		rangeEnd() {
			this.$parent.rangeStart = this.$moment(this.rangeStart).format(MDY_FORMAT);
			this.$parent.rangeEnd = this.$moment(this.rangeEnd).format(MDY_FORMAT);
		},
	},
	mounted() {
		if (Object.keys(this.$user.details()).length > 0) {
			let user = this.$user.details().id;
			if (this.$route.params.distributorId) {
				user = this.$route.params.distributorId;
			}
			this.periodInfo.getDistributorPeriodInfo(user).then((response) => {
				this.rangeStart = response.response.dates.start.date;
				this.rangeEnd = response.response.dates.end.date;
			});
		}
	},
	methods: {
		getCurrencyIcon(currencyCode) {
			return (typeof this.currencyIcons[currencyCode] !== 'undefined') ? this.currencyIcons[currencyCode] : this.defaultCurrencyIcon;
		},
	},
};
</script>
