<template>
	<div
		v-if="!!volumeComparitionData.length"
		class="row">
		<div class="col">
			<div class="card mb-0">
				<div class="card-header bg-dark">
					<!-- <div
						v-b-tooltip.hover
						:title="translate('comparision_tooltip')"
						class="float-right text-muted">
						<i class="fas fa-lg fa-info-circle" />
					</div> -->
					<h5 class="mb-0">
						{{ translate('volume_comparison') }}
					</h5>
				</div>
				<div class="card-body p-0">
					<table-widget
						:data="volumeComparitionData"
						:first-row-label="translate('bonus_volume')"
						:second-row-label="translate('new_distributors')"
						:third-row-label="translate('personally_sponsored')"
						:first-col-label="translate('left')"
						:second-col-label="translate('right')"
						:third-col-label="translate('total')" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import TableWidget from '@/components/TableWidget3x3';
import Dashboard from '@/util/Dashboard';

export default {
	name: 'VolumeComparition',
	messages: [DashboardTranslations],
	components: {
		TableWidget,
	},
	data() {
		return {
			volumeComparition: new Dashboard(),
		};
	},
	computed: {
		volumeComparitionData() {
			try {
				const { data } = this.volumeComparition.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		userId() {
			const { distributorId } = this.$route.params;
			if (typeof distributorId !== 'undefined') {
				return distributorId;
			}
			return this.$user.details().id;
		},
	},
	mounted() {
		if (Object.keys(this.$user.details()).length > 0) {
			this.volumeComparition.getVolumeComparition(this.userId);
		}
	},
};
</script>
