<!-- eslint-disable vue/no-v-html -->
<template>
	<b-row class="no-gutters justify-content-end">
		<div class="p-1 pb-2 col-12">
			<template v-if="!loading">
				<div class="row col-12 no-gutters justify-content-center d-flex">
					<div
						class="py-2">
						<div
							v-b-tooltip.hover.center
							:title="translate('current_progress_info')"
							class="d-inline">
							<div class="row justify-content-center px-3 h6">
								<b>{{ translate('estimated_amount') }}</b>: *
							</div>
						</div>
						<div
							v-if="!loading"
							class="row justify-content-center px-3 mb-2">
							<b><span class="text-primary h1">{{ estimatedAmount }}</span></b>
						</div>
						<hr class="my-2 py-1">
					</div>
					<b-row class="col-12">
						<b-col class="col-lg-4">
							<div
								:class="'text-muted font-weight-bold'"
								class="d-flex justify-content-center">
								{{ translate('bonus') }}
							</div>
							<div
								v-if="!loading"
								class="text-muted text-truncate d-flex justify-content-center">
								{{ earning }}%
							</div>
						</b-col>
						<b-col class="col-lg-4">
							<div
								:class="'text-muted font-weight-bold'"
								class="d-flex justify-content-center">
								{{ translate('orders') }}
							</div>
							<div
								v-if="!loading"
								class="text-muted text-truncate d-flex justify-content-center">
								<referral-bonus-orders
									hover-id="pending"
									style="display:inline"
									:orders="referralBonusOrders" />
							</div>
						</b-col>
						<b-col class="col-lg-4">
							<div
								:class="'text-muted font-weight-bold'"
								class="d-flex justify-content-center">
								{{ translate('paid') }}
							</div>
							<div
								v-if="!loading"
								class="text-muted text-truncate d-flex justify-content-center">
								{{ paidAmount }}
							</div>
						</b-col>
					</b-row>
				</div>
				<b-row
					v-if="Object.keys(nextBonus || {}).length > 0"
					no-gutters
					class="mt-3 mx-2 justify-content-around border py-2 px-1 legs-wrapper">
					<b-col
						cols="12"
						class="align-items-center text-center"
						v-html="translate(nextBonus.lang_key, {
							package: nextBonus.package.min,
							enrollments_qty: nextBonus.enrollments.min,
							enrollments_package: translate(nextBonus.enrollments.min_package),
							earning: nextBonus.earning
						})" />
					<referral-bonus-milestone
						v-if="nextBonus.package.min"
						:centered="false"
						:completed="nextBonus.package.completed">
						<template #milestone-data>
							{{ translate(nextBonus.package.min) }}
						</template>
					</referral-bonus-milestone>
					<referral-bonus-milestone
						v-if="nextBonus.enrollments.min > 1"
						:completed="nextBonus.enrollments.current >= nextBonus.enrollments.min">
						<template #milestone-data>
							<div>
								<p>
									{{ translate(`${nextBonus.lang_key}_requirements`, {
										enrollments_qty: nextBonus.enrollments.min,
										enrollments_package: translate(nextBonus.enrollments.min_package),
									}) }}
								</p>
								<div class="align-items-center text-center">
									<img
										v-for="i in nextBonus.enrollments.min"
										:key="i"
										:src="require(`@/assets/images/themes/${themeName}/qualified/${(i <= nextBonus.enrollments.current) ? 'customer_30_green' : 'customer_30_gray'}.png`)"
										alt="">
								</div>
							</div>
						</template>
					</referral-bonus-milestone>
				</b-row>
			</template>
			<is-loading
				:loading-label="translate('loading')"
				:loading="loading" />
		</div>
	</b-row>
</template>
<script>
import {
	Dashboard as DashboardMessages,
	Dates,
	Packages,
	Promotions,
} from '@/translations';
import Dashboard from '@/util/Dashboard';
import WindowSizes from '@/mixins/WindowSizes';
import ReferralBonusOrders from '@/views/Dashboard/components/widgets/ReferralBonusOrders';
import ReferralBonusMilestone from '@/views/Dashboard/components/widgets/ReferralBonusMilestone.vue';

export default {
	name: 'ReferralBonus',
	messages: [DashboardMessages, Dates, Packages, Promotions],
	components: {
		ReferralBonusMilestone,
		ReferralBonusOrders,
	},
	mixins: [WindowSizes],
	props: {
		userId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			referralBonus: new Dashboard(),
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		data() {
			try {
				return this.referralBonus.data.response.data.response;
			} catch (error) {
				return {};
			}
		},
		loading() {
			return !!this.referralBonus.data.loading;
		},
		earning() {
			try {
				return this.data.earning;
			} catch (error) {
				return '';
			}
		},
		estimatedAmount() {
			try {
				return this.data.totals.estimated.formatted;
			} catch (error) {
				return '$ 0.00 USD';
			}
		},
		paidAmount() {
			try {
				return this.data.totals.paid.formatted;
			} catch (error) {
				return '$ 0.00 USD';
			}
		},
		ordersQty() {
			try {
				return this.data.orders.length;
			} catch (error) {
				return 0;
			}
		},
		totalPaid() {
			try {
				return this.data.orders.total_paid;
			} catch (error) {
				return 0;
			}
		},
		nextBonus() {
			try {
				return this.data.next_bonus;
			} catch (error) {
				return {};
			}
		},
		bonusTotals() {
			try {
				return this.referralBonus.data.response.data.response.totals;
			} catch (error) {
				return [];
			}
		},
		referralBonusOrders() {
			try {
				return this.data.orders;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		loading(newValue) {
			this.$emit('changeLoading', newValue);
		},
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			this.referralBonus.getReferralBonus(this.userId);
		},
	},
};
</script>
<style scoped>
.legs-wrapper{
	border-radius:5px;
	position: relative;
}
</style>
