<template>
	<div v-if="!loading && imageUrls.length > 0">
		<Carousel :images="imageUrls" />
		<hr>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Banner from '@/util/Banner';
import Carousel from './Carousel';

export default {
	name: 'Banners',
	components: {
		Carousel,
	},
	mixins: [WindowSizes],
	props: {
		uid: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			banners: new Banner(),
		};
	},
	computed: {
		loading() {
			return !!this.banners.data.loading;
		},
		data() {
			try {
				const { data } = this.banners.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		imageUrls() {
			const isMobile = ['xs', 'sm'].includes(this.windowWidth);
			return this.data.map((banner) => ({
				id: banner.id,
				src: (!isMobile ? banner.attributes.image : banner.attributes.mobile_image),
				hasUrl: banner.attributes.has_url,
				url: banner.attributes.url,
			}));
		},
	},
	mounted() {
		this.banners.listBanners(this.uid ? this.uid : this.$user.details().id, { show_in_dashboard: true });
	},
};
</script>
