<template>
	<div
		v-if="!!Object.keys(accountInfoData).length"
		class="row h-100 account-info-widgets">
		<div class="col h-100">
			<div class="row h-50">
				<div class="col-12 col-lg-4">
					<div class="h-100">
						<distributor-info-widget
							:account-info-data="accountInfoData"
							@fullyQualified="isDistributorFullyQualified" />
					</div>
				</div>
				<div class="col-12 mt-3 mt-lg-0 col-lg-4">
					<div class="h-100">
						<rank-progress />
					</div>
				</div>
				<div class="col-12 col-lg-4 mt-3 mt-lg-0">
					<div class="h-100">
						<promotions
							:account-info-data="accountInfoData"
							:fully-qualified="fullyQualified" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Dashboard from '@/util/Dashboard';
import { Ranks } from '@/translations';
import DistributorInfoWidget from './widgets/DistributorInfoWidget';
import RankProgress from './RankProgress';
import Promotions from './Promotions';

export default {
	name: 'AccountInfo',
	messages: [Ranks],
	components: {
		DistributorInfoWidget,
		RankProgress,
		Promotions,
	},
	data() {
		return {
			accountInfo: new Dashboard(),
			themeName: process.env.VUE_APP_THEME,
			fullyQualified: false,
		};
	},
	computed: {
		accountInfoData() {
			try {
				const { response } = this.accountInfo.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		userId() {
			const { distributorId } = this.$route.params;
			if (typeof distributorId !== 'undefined') {
				return distributorId;
			}
			return this.$user.details().id;
		},
	},
	mounted() {
		if (Object.keys(this.$user.details()).length > 0) {
			this.accountInfo.getAccountInfo(this.userId);
		}
	},
	methods: {
		isDistributorFullyQualified(newValue) {
			this.fullyQualified = newValue;
		},
	},
};
</script>

<style lang="scss">
.account-info-widgets {
	.card{
		min-height:200px;
	}
}
</style>
