<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div v-if="status === 'inactive'">
			<div class="row">
				<div class="col-12">
					<b-alert
						show
						variant="info">
						<b-row>
							<b-col class="text-center">
								<h3>{{ translate('predistributor_alert_title') }}</h3>
								<p>{{ translate('predistributor_alert_text') }}</p>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-center">
								<button
									class="btn btn-primary">
									{{ translate('predistributor_alert_button') }}
								</button>
							</b-col>
						</b-row>
					</b-alert>
				</div>
			</div>
		</div>
		<div v-else>
			<div
				v-if="hasAnnouncements"
				class="row">
				<div
					v-for="announcementInfo in announcements"
					:key="announcementInfo.id"
					class="col-12">
					<show-announcement
						:id="Number(announcementInfo.id)"
						:allow-to-hide="!!announcementInfo.attributes.allow_to_hide"
						:theme="announcementInfo.attributes.theme"
						:title="announcementInfo.attributes.title"
						:content="announcementInfo.attributes.content"
						:regards="announcementInfo.attributes.regards"
						:signature="announcementInfo.attributes.signature"
						@hide="hideAnnouncement" />
				</div>
			</div>
			<br v-if="hasAnnouncements">
			<div class="row mb-4 mb-sm-0">
				<div class="col-12 col-md-6 col-lg-auto">
					<div class="h4 d-inline-block">
						<span class="d-none d-md-inline-block">{{ translate('welcome') }}</span> {{ $user.details().fullname }}!
					</div>
					<p class="mb-2">
						<span>{{ translate('distributor_id') }}: </span><span class="font-weight-bold">{{ $user.details().id }}</span>
					</p>
				</div>
				<div class="col-12 col-md-6 col-lg-auto ml-lg-auto">
					<template v-if="!loading && hasData">
						<b-alert
							:class="['xs', 'sm'].includes(windowWidth) ? 'mb-0' : ''"
							:variant="purchaseInfo.variant"
							class="w-100 text-center py-3"
							show>
							<div
								class="h5 m-0"
								v-html="purchaseInfo.text" />
						</b-alert>
					</template>
					<template v-else>
						<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('loading') }}
					</template>
				</div>
			</div>
			<div class="row mt-2 mb-2">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
					class="col-12 col-lg-6 mh-100 mb-2">
					<template v-if="!loading && hasData">
						<replicated-link
							:link="data.replicated_site.url"
							:loading="loading" />
					</template>
					<template v-else>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="loading" />
					</template>
				</div>
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
					class="col-12 col-lg-6 mb-2">
					<template v-if="!loadingUserInfo && hasDataUserInfo">
						<div class="bg-dark p-3 h-100">
							<div class="row mt-2">
								<div
									:class="windowWidth === 'md' ? 'col-sm-7' : 'col-sm-6'"
									class="col-12">
									<div>
										<span class="text-primary"> {{ translate(dataUserInfo.binary_placement_preference) }}</span>
										<b-button
											v-b-modal="'predistributor-modal'"
											variant="link"
											class="p-0 ml-2 align-bottom">
											<i class="fas fa-exclamation-circle" />
										</b-button>
										<div class="text-light text-uppercase font-weight-bold">
											{{ translate('power_leg') }}
										</div>
									</div>
								</div>
								<div
									:class="windowWidth === 'md' ? 'col-sm-5' : 'col-sm-6'"
									class="col-12">
									<button
										:class="['xs', 'sm'].includes(windowWidth) ? 'mt-2' : 'float-right'"
										:style="['xs', 'sm', 'md'].includes(windowWidth) ? 'width: 85%;' : ''"
										type="button"
										class="btn bg-success"
										@click="$router.push({ name: 'AccountInformation' })">
										<i class="fas fa-cogs" />&nbsp; {{ translate('settings') }}
									</button>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="loadingUserInfo"
							class="mx-auto" />
					</template>
				</div>
			</div>
			<!-- <div class="row mb-3">
				<div class="col-12">
					<div class="card mb-0 h-100">
						<div class="card-header bg-dark">
							<h5 class="mb-0 d-inline-block">
								{{ translate('remaining_invitations') }}: {{ translate('out_of', {remaining:remaining, total:total}) }}
							</h5>
							<h5
								v-b-tooltip.hover
								:title="translate('remaining_invitations_info', { total1: total, total2: total })"
								class="mb-0 float-right">
								<i class="fas fa-info-circle" />
							</h5>
						</div>
						<div class="card-body">
							<remaining-invitations
								ref="createInvitation" />
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="card mb-0 h-100">
						<div class="card-header bg-dark">
							<h5 class="mb-0">
								{{ translate('invitations') }}
							</h5>
						</div>
						<div class="card-body">
							<invitations-table
								ref="invitationsTable" />
						</div>
					</div>
				</div>
			</div> -->
			<b-modal
				id="predistributor-modal"
				:title="translate('power_leg')"
				ok-only>
				{{ translate('predistributor_modal') }}
			</b-modal>
		</div>
	</div>
</template>

<script>
import { PURCHASE_DATE, MDY_FORMAT } from '@/settings/Dates';
import { Dashboard as messages } from '@/translations';
// import { Invitations as messages } from '@/translations';
// import Invitations from '@/util/Invitations';
// import RemainingInvitations from '@/views/Invitations/RemainingInvitations';
// import InvitationsTable from '@/views/Invitations';
import WindowSizes from '@/mixins/WindowSizes';
import Announcement from '@/util/Announcement';
import ShowAnnouncement from '@/components/Announcement';
import AccountInformation from '@/util/Dashboard';
import ReplicatedLink from './ReplicatedLink';

export default {
	name: 'PreDistributorDashboard',
	components: {
		ShowAnnouncement,
		ReplicatedLink,
		// RemainingInvitations,
		// InvitationsTable,
	},
	messages,
	mixins: [WindowSizes],
	data() {
		return {
			announcement: new Announcement(),
			hideAnnouncementEndpoint: new Announcement(),
			// availableInvitations: new Invitations(),
			accountInformation: new AccountInformation(),
			userInformation: new AccountInformation(),
			status: this.$user.details().status,
		};
	},
	computed: {
		// total() {
		// 	try {
		// 		return this.availableInvitations.data.response.data.response.total_invitations;
		// 	} catch (error) {
		// 		return 1;
		// 	}
		// },
		// remaining() {
		// 	try {
		// 		return this.availableInvitations.data.response.data.response.invitations_available;
		// 	} catch (error) {
		// 		return 1;
		// 	}
		// },
		announcements() {
			try {
				const { data } = this.announcement.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasAnnouncements() {
			const response = Object.keys(this.announcements).length;
			return !!response;
		},
		data() {
			try {
				const { response } = this.accountInformation.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			const response = Object.keys(this.data).length;
			return !!response;
		},
		loading() {
			return !!this.accountInformation.data.loading;
		},
		dataUserInfo() {
			try {
				const { response } = this.userInformation.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		hasDataUserInfo() {
			const response = Object.keys(this.dataUserInfo).length;
			return !!response;
		},
		loadingUserInfo() {
			return !!this.userInformation.data.loading;
		},
		purchaseInfo() {
			let variant = PURCHASE_DATE.default.class;
			let text = this.translate('your_account_is_active_until', { date: this.$moment(this.data.next_purchase_date.date).format(MDY_FORMAT) });
			if (this.data.status === 'inactive') {
				variant = PURCHASE_DATE.expired.class;
				text = this.translate('your_account_is_inactive', { link: this.$router.resolve({ name: 'MainStore' }).href });
			}
			return {
				variant,
				text,
			};
		},
	},
	mounted() {
		const userId = this.$user.details().id;
		this.accountInformation.getAccountInfo(userId);
		this.userInformation.getUserInfo(userId);
		this.announcement.getAnnouncementsList(userId);
	},
	methods: {
		// getInvitationsData() {
		// 	this.availableInvitations.getInvitationsUserInfo();
		// 	this.$refs.invitationsTable.invitations.getInvitations();
		// },
		hideAnnouncement(id) {
			let user = this.$user.details().id;
			if (this.$route.params.distributorId) {
				user = this.$route.params.distributorId;
			}
			this.hideAnnouncementEndpoint.hideAnnouncement(user, id).then(() => {
				this.announcement.getAnnouncementsList(user);
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
	},
};
</script>
