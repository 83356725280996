export default {};

const DISTRIBUTOR_ENROLLMENT = 'distributor_enrollment';
export const VISITOR_ENROLLMENT = 'visitor_enrollment';
export const AFFILIATE_ENROLLMENT = 'affiliate_enrollment';

export const USE_USERNAME = [DISTRIBUTOR_ENROLLMENT, VISITOR_ENROLLMENT, AFFILIATE_ENROLLMENT];

const activeColor = '#DF703D'; // Primary
const inactiveColor = '#A8A19B';

const userPlusIcon = 'fas fa-user-plus';
const userIcon = 'fas fa-user';
const starIcon = 'fas fa-star';
const exclamationIcon = 'fas fa-exclamation';

export const NOTIFICATION_ICON = {
	distributor_enrollment: {
		icon: userPlusIcon,
		color: activeColor,
	},
	visitor_enrollment: {
		icon: userPlusIcon,
		color: activeColor,
	},
	rank_update: {
		icon: starIcon,
		color: activeColor,
	},
	user_deactivated: {
		icon: userIcon,
		color: inactiveColor,
	},
	become_distributor: {
		icon: userPlusIcon,
		color: activeColor,
	},
	default: {
		icon: exclamationIcon,
		color: activeColor,
	},
};

export const TRANSLATED_KEYS = [
	'rank_code_name',
	'package',
	'user_type',
];
