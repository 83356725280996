<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			:class="colorTransformer(theme)"
			class="alert my-1 mb-2 text-left"
			role="alert">
			<!-- <h4
			class="mb-2"
			v-html="title" /> -->
			<i
				v-if="allowToHide"
				v-b-tooltip.hover
				:title="translate('close')"
				class="float-right m-1 ml-3 mb-2 fa fa-times pointer"
				@click="hide" />
			<h5
				class="font-weight-normal mb-2"
				v-html="content" />
			<!-- <h5
			class="font-weight-normal text-center"
			v-text="regards" /> -->
			<!-- <h5
			class="text-center"
			v-text="signature" /> -->
		</div>
	</div>
</template>

<script>
import { Announcements as messages } from '@/translations';

export default {
	name: 'ShowAnnouncement',
	messages,
	props: {
		id: {
			type: Number,
			default: 0,
		},
		allowToHide: {
			type: Boolean,
			default: false,
		},
		theme: {
			type: String,
			default: '',
		},
		// title: {
		// 	type: String,
		// 	default: '',
		// },
		content: {
			type: String,
			default: '',
		},
		// regards: {
		// 	type: String,
		// 	default: '',
		// },
		// signature: {
		// 	type: String,
		// 	default: '',
		// },
	},
	data() {
		return {
			alert: new this.$Alert(),
		};
	},
	methods: {
		colorTransformer(color) {
			return `alert-${color}`;
		},
		hide() {
			this.alert.confirmation(this.translate('confirm_hide_title'), this.translate('confirm_hide_text')).then(() => {
				this.$emit('hide', this.id);
			}).catch(() => {});
		},
	},
};
</script>
