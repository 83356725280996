<template>
	<div
		class="row">
		<div class="col">
			<div class="card mb-0">
				<div class="card-header bg-dark">
					<h5 class="mb-0">
						{{ translate('organization_notifications') }}
					</h5>
				</div>
				<div
					class="card-body p-0"
					style="height: 23rem; overflow-y: auto"
					:style="loading || !hasData ? 'display: flex; flex-direction: column; justify-content: center; align-items: center' : ''">
					<is-loading
						:loading="loading"
						:loading-label="translate('loading')"
						:no-data-label="translate('no_new_activity')"
						:has-data="hasData" />
					<organization-notification-item
						v-for="(item, index) in organizationNotificationsData"
						:key="index"
						:created-at="item.attributes.created_at"
						:user="item.attributes.user"
						:causer="item.attributes.causer"
						:code-name="item.attributes.code_name"
						:options="notificationOptions(item.attributes.options)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import OrganizationNotificationItem from '@/components/OrganizationNotificationItem';
import {
	OrganizationNotifications as OrganizationNotificationsMessages,
	Products as ProductsMessages,
	Ranks as RanksMessages,
	Packages,
} from '@/translations';
import {
	TRANSLATED_KEYS as optionsKeysToBeTranslated,
} from '@/settings/OrganizationNotifications';
import Dashboard from '@/util/Dashboard';

export default {
	name: 'OrganizationNotifications',
	messages: [OrganizationNotificationsMessages, RanksMessages, ProductsMessages, Packages],
	components: {
		OrganizationNotificationItem,
	},
	data() {
		return {
			organizationNotifications: new Dashboard(),
			optionsKeysToBeTranslated,
		};
	},
	computed: {
		organizationNotificationsData() {
			try {
				const { data } = this.organizationNotifications.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		userId() {
			const { distributorId } = this.$route.params;
			if (typeof distributorId !== 'undefined') {
				return distributorId;
			}
			return this.$user.details().id;
		},
		loading() {
			return !!this.organizationNotifications.data.loading;
		},
		hasData() {
			const response = Object.keys(this.organizationNotificationsData).length;
			return !!response;
		},
	},
	mounted() {
		if (Object.keys(this.$user.details()).length > 0) {
			this.organizationNotifications.getOrganizationNotifications(this.userId);
		}
	},
	methods: {
		notificationOptions(options) {
			if (options !== null) {
				const opts = Object({ ...options });
				Object.keys(options).forEach((opt) => {
					opts[opt] = this.optionsKeysToBeTranslated.includes(opt) ? this.translate(`${opts[opt]}`) : opts[opt];
				});
				return opts;
			}

			return { };
		},
	},
};
</script>
