<template>
	<div v-if="requestedUser">
		<popups
			v-if="currentAlert === 'other'"
			@done="nextAlert" />
		<wallet-alert
			v-if="currentAlert === 'wallet'"
			@done="nextAlert" />
		<promotions-alert
			v-if="currentAlert === 'promotions'"
			:promos-info="freeProductsPromotions"
			@done="nextAlert" />
		<v-club-popup
			v-if="currentAlert === 'v_club'"
			@done="nextAlert" />
		<div class="row mb-sm-0">
			<div class="col-12 col-md-6 col-lg-auto">
				<div class="h4 d-inline-block">
					<span class="d-none d-md-inline-block">{{ translate('welcome') }}</span> {{ requestedUser.fullname }}!
					<span>({{ translate('affiliate_id') }}: </span><span class="font-weight-bold">{{ requestedUser.id }}</span>)
				</div>
			</div>
		</div>
		<b-row class="h-100">
			<b-col
				v-if="!isJns()"
				:cols="!freeProductsPromotionsLoading && Object.keys(freeProductsPromotions).length === 0 ? '12' : '6'"
				class="mb-2"
				:style="{
					'min-height': promotionsAutoHeight ? 'auto' : '320px',
				}">
				<affiliate-promotions :account-info-data="{user_id: requestedUser.id}" />
			</b-col>
			<b-col
				cols="6"
				class=" mb-2">
				<free-products-promotions
					v-if="!freeProductsPromotionsLoading && Object.keys(freeProductsPromotions).length > 0"
					:promotions="freeProductsPromotions" />
				<is-loading
					v-if="freeProductsPromotionsLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="freeProductsPromotionsLoading"
					class="mx-auto" />
			</b-col>
		</b-row>
		<b-row>
			<b-col
				cols="6 mb-2">
				<autoship
					v-if="!isJns() && !loading && hasData"
					:next-autoship-date="data.next_autoship_date"
					:dark-theme="true" />
				<is-loading
					v-else
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					class="mx-auto" />
			</b-col>
			<b-col
				cols="6">
				<replicated-link-selector
					v-if="!loading && hasData"
					class="mb-2"
					:links="data.replicated_links" />
				<div v-else>
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading" />
				</div>
			</b-col>
			<b-col
				cols="6">
				<template v-if="!loading && hasData">
					<link-widget
						class="mb-2"
						:title="translate('become_customer_link')"
						:description="translate('become_customer_link_description')"
						:link="data.other_links.become_a_customer" />
				</template>
				<div v-else>
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading" />
				</div>
			</b-col>
			<b-col
				cols="6">
				<template v-if="!loading && hasData">
					<link-widget
						:title="translate('become_affiliate_link')"
						:description="translate('become_affiliate_link_description')"
						:link="data.other_links.become_an_affiliate" />
				</template>
				<div v-else>
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading" />
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col class="mb-1">
				<organization-notifications />
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col class="mb-1">
				<b-card
					v-if="hasSponsorData && !requestedUser.no_sponsor"
					class="h-100 border-top-darken">
					<template>
						<b-row>
							<b-col>
								<b><p class="h5 d-inline-block">{{ translate('sponsor_information') }}</p></b>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="col-12 col-md-2 col-2 mt-2 mb-2">
								<p class="mb-0 p-0 text-left">
									{{ accountData.sponsor }}
								</p>
								<p class="mb-0">
									<b>{{ translate('username') }}</b>
								</p>
							</b-col>
							<b-col class="col-12 col-md-2 col-2 mt-2 mb-2">
								<p class="mb-0 p-0 text-left">
									{{ accountData.sponsor_name }}
								</p>
								<p class="mb-0">
									<b>{{ translate('name') }}</b>
								</p>
							</b-col>
							<b-col class="col-12 col-md-3 col-3 mt-2 mb-2">
								<p class="mb-0 p-0 text-left text-muted">
									<a
										:href="'mailto:' + accountData.sponsor_email"
										target="_blank">{{ accountData.sponsor_email }}</a>
								</p>
								<p class="mb-0">
									<b>{{ translate('email') }}</b>
								</p>
							</b-col>
							<b-col class="col-12 col-md-5 col-5 mt-2 mb-2">
								<p class="mb-0 p-0 text-left text-muted">
									<a
										:href="accountData.sponsor_replicated_site"
										target="_blank">{{ accountData.sponsor_replicated_site }}</a>
								</p>
								<p class="mb-0">
									<b>{{ translate('replicated_site') }}</b>
								</p>
							</b-col>
						</b-row>
					</template>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Popups from '@/components/Popups';
import WalletAlert from '@/components/WalletAlert';
import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard as messages, Grids } from '@/translations';
import AccountInformation from '@/util/Dashboard';
import SponsorInformation from '@/util/Profile';
import User from '@/util/User';

import AffiliatePromotions from './AffiliatePromotions';
import Autoship from './Autoship';
import LinkWidget from './LinkWidget';
import OrganizationNotifications from './OrganizationNotifications';
import ReplicatedLinkSelector from './ReplicatedLinkSelector';
import Promotions from '@/util/Promotions';
import FreeProductsPromotions from '@/views/Dashboard/components/FreeProductsPromotions.vue';
import PromotionsAlert from '@/components/PromotionsAlert';
import VClubPopup from '@/components/VClubPopup/index.vue';

export default {
	name: 'AffiliateDashboard',
	components: {
		PromotionsAlert,
		FreeProductsPromotions,
		AffiliatePromotions,
		Autoship,
		LinkWidget,
		OrganizationNotifications,
		Popups,
		ReplicatedLinkSelector,
		WalletAlert,
		VClubPopup,
	},
	messages: [messages, Grids],
	mixins: [WindowSizes],
	data() {
		return {
			userUtil: new User(),
			accountInformation: new AccountInformation(),
			sponsorInformation: new SponsorInformation(),
			alertOrder: ['other', 'wallet', 'promotions', 'v_club'],
			requestedUser: null,
			promotionsInfo: new Promotions(),
		};
	},
	computed: {
		data() {
			try {
				const { response } = this.accountInformation.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		promotionsAutoHeight() {
			try {
				const smallScreen = ['xs', 'sm'].includes(this.windowWidth);
				const autoshipActive = this.data.next_autoship_date == null || typeof this.data.next_autoship_date === 'undefined';
				return smallScreen || autoshipActive;
			} catch (error) {
				return true;
			}
		},
		hasData() {
			const response = Object.keys(this.data).length;
			return !!response;
		},
		loading() {
			return !!this.accountInformation.data.loading;
		},
		currentAlert() {
			return this.alertOrder[0];
		},
		response() {
			try {
				const { data } = this.sponsorInformation.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		accountData() {
			try {
				return this.response.data.attributes;
			} catch (error) {
				return {};
			}
		},
		hasSponsorData() {
			try {
				return typeof this.accountData.sponsor !== 'undefined';
			} catch (error) {
				return false;
			}
		},
		freeProductsPromotions() {
			try {
				const { response } = this.promotionsInfo.data.response.data;
				if (Array.isArray(response) && response.length === 0) {
					return {};
				}
				return response;
			} catch (error) {
				return {};
			}
		},
		freeProductsPromotionsLoading() {
			try {
				return !!this.promotionsInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		let userId = this.$user.details().id;
		// It means that a corporate user is trying to see an affiliate's dashboard
		if (this.$route.params.userId) {
			userId = this.$route.params.userId;
			this.userUtil.fetchUser({ user_id: userId }).then((response) => this.userData(response.response));
		} else { // It means that an affiliate is trying to see his own dashboard
			this.userData(this.$user.details());
		}
		this.promotionsInfo.getPromotionsAlert(userId);
	},
	methods: {
		userData(details) {
			this.requestedUser = details;
			this.accountInformation.getAccountInfoCustomer(this.requestedUser.id);
			this.sponsorInformation.getCustomerInformation(this.requestedUser.id);
			if (!this.requestedUser.no_sponsor) {
				this.sponsorInformation.getCustomerInformation(this.requestedUser.id);
			}
		},
		nextAlert() {
			this.alertOrder.shift();
		},
	},
};
</script>
