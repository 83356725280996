<template>
	<button
		v-clipboard="() => copyContent"
		:class="btnClass || 'bg-success'"
		class="btn"
		@click="copy">
		<i
			:class="iconClass || 'fas fa-copy'"
			class="fas fa-link" /> {{ buttonText || translate('copy') }}
	</button>
</template>
<script>
export default {
	props: {
		copyContent: {
			type: String,
			required: true,
		},
		iconClass: {
			type: String,
			default: '',
		},
		btnClass: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: '',
		},
	},
	methods: {
		copy() {
			const alert = new this.$Alert();
			alert.toast('success', this.translate('copied'));

			this.$emit('copied');
		},
	},
};
</script>
