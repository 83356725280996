<template>
	<div />
</template>
<script>
import { admin } from '@/settings/Roles';
import { Alerts, OrderVerifications } from '@/translations';
import Alert from '@/util/Alert';
import Order from '@/util/Order';

export default {
	name: 'OrderDocumentAlert',
	messages: [Alerts, OrderVerifications],
	data() {
		return {
			admin,
			theme: process.env.VUE_APP_THEME,
			alert: new Alert(),
			orderVerification: new Order(),
		};
	},
	computed: {
		alerts() {
			try {
				return this.orderVerification.data.response.data.response;
			} catch (e) {
				return [];
			}
		},
	},
	mounted() {
		if (!this.admin.includes(this.$user.details().type)) {
			this.showAlert();
		} else {
			this.$emit('done');
		}
	},
	methods: {
		showAlert() {
			this.orderVerification.getOrderDocumentAlert().then(() => {
				const style = getComputedStyle(document.body);

				if (this.alerts.show) {
					const config = {
						config: {
							icon: 'warning',
							allowEscapeKey: false,
							allowOutsideClick: false,
							showLoaderOnConfirm: true,
							showCancelButton: true,
							cancelButtonColor: style.getPropertyValue('--gray'),
							confirmButtonColor: style.getPropertyValue('--primary'),
							confirmButtonText: this.translate('alert_confirm_text'),
							cancelButtonText: this.translate('alert_cancel_text'),
						},
					};
					this.alert.confirmation(
						this.translate('alert_info_title'),
						this.translate('alert_info_message'),
						config,
						true,
					).then(() => {
						this.$router.push({ name: 'OrdersAll' });
					}).catch(() => {
					}).finally(() => {
						this.$emit('done');
					});
				} else {
					this.$emit('done');
				}
			});
		},
	},
};
</script>
