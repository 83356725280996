import { Line } from 'vue-chartjs';
import { Months as messages } from '@/translations';
import { YY_FORMAT } from '@/settings/Dates';

const formatterNumber = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 0,
});

export default {
	extends: Line,
	messages,
	props: {
		chartdata: {
			type: Object,
			default: null,
		},
		xAxisLabel: {
			type: String,
			default: '',
		},
		yAxisLabel: {
			type: String,
			default: '',
		},
		popOverLabel: {
			type: Array,
			default() {
				return [];
			},
		},
		dataImport: {
			type: Array,
			default() {
				return [];
			},
		},
		showMonthLabel: {
			type: Boolean,
			default: false,
		},
		weekLabel: {
			type: String,
			default: 'Week',
		},
		currency: {
			type: Boolean,
			default: false,
		},
		currencyCode: {
			type: String,
			default: 'USD',
		},
	},
	data() {
		return {
			formatterUSD: new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: this.currencyCode,
				minimumFractionDigits: 0,
			}),
			formatterNumber,
			chartBg: '',
			data: {
				labels: [],
				datasets: [],
			},
			options: {
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							let label = data.datasets[tooltipItem.datasetIndex].label || '';
							if (label) {
								label += ': ';
							}
							label += this.currency ? this.formatterUSD.format(tooltipItem.yLabel) : this.formatterNumber.format(tooltipItem.yLabel);
							return label;
						},
						title: (tooltipItem) => {
							const [tooltipItemArray] = tooltipItem;
							if (!this.showMonthLabel) {
								return `${this.weekLabel} ${tooltipItemArray.xLabel}`;
							}
							return tooltipItemArray.xLabel;
						},
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				legend: { display: false },
				scales: {
					yAxes: [{
						scaleLabel: {
							display: true,
							labelString: this.yAxisLabel,
						},
						ticks: {
							min: 0,
							callback: (value) => (this.currency ? this.formatterUSD.format(value) : this.formatterNumber.format(value)),
						},
					}],
					xAxes: [{
						scaleLabel: {
							display: true,
							labelString: this.xAxisLabel,
						},
					}],
				},
			},
		};
	},
	computed: {
		graphData() {
			const data = [];
			if (!this.multiChart) {
				this.dataImport.forEach((element) => {
					data.push(element.total);
				});
			} else {
				this.dataImport.forEach((dataset) => {
					const newDataset = dataset.map((element) => (element.total));
					data.push(newDataset);
				});
			}
			return data;
		},
		graphLabels() {
			const data = [];
			if (!this.multiChart) {
				this.dataImport.forEach((element) => {
					if (!this.showMonthLabel) {
						data.push(element.code);
					} else if (this.showMonthLabel) {
						data.push(`${this.translate(element.code)} ${this.$moment(this.$moment().year(element.year)).format(YY_FORMAT)}`);
					}
				});
			} else {
				this.dataImport[0].forEach((element) => {
					if (!this.showMonthLabel) {
						data.push(element.code);
					} else if (this.showMonthLabel) {
						data.push(`${this.translate(element.code)} ${this.$moment(this.$moment().year(element.year)).format(YY_FORMAT)}`);
					}
				});
			}
			return data;
		},
		multiChart() {
			return Array.isArray(this.dataImport[0]);
		},
	},
	mounted() {
		this.data.labels = this.graphLabels;
		this.fillData();
		this.renderChart(this.data, this.options);
	},
	methods: {
		setBorder() {
			const style = getComputedStyle(document.body);
			const data = style.getPropertyValue('--primary');
			return data;
		},
		setBackground() {
			const style = getComputedStyle(document.body);
			const primary = style.getPropertyValue('--primary');
			const bg = (color) => {
				const r = parseInt(color.slice(1, 3), 16);
				const g = parseInt(color.slice(3, 5), 16);
				const b = parseInt(color.slice(5, 7), 16);
				return `rgba(${r},${g},${b},0.3)`;
			};
			const data = bg(primary.trim());
			return data;
		},
		setBorderBackground() {
			const colorSettings = [
				{
					border: 'rgba(235,119,47,0.7)',
					background: 'rgba(235,119,47,0.2)',
				},
				{
					border: 'rgba(47,166,235,0.7)',
					background: 'rgba(47,166,235,0.5)',
				},
			];
			return colorSettings;
		},
		fillData() {
			if (!this.multiChart) {
				const newData = {
					label: this.popOverLabel,
					backgroundColor: this.setBackground,
					borderColor: this.setBorder,
					borderWidth: 1,
					data: this.graphData,
				};
				this.data.datasets.push({ ...newData });
			} else {
				let popoverLabelPosition = 0;
				let colorPosition = 0;
				const color = this.setBorderBackground();
				this.graphData.forEach((data) => {
					const newData = {
						label: this.popOverLabel[popoverLabelPosition],
						backgroundColor: colorPosition % 2 === 0 ? color[0].background : color[1].background,
						borderColor: colorPosition % 2 === 0 ? color[0].border : color[1].border,
						borderWidth: 1,
						data,
					};
					colorPosition += 1;
					popoverLabelPosition += 1;
					this.data.datasets.push({ ...newData });
				});
			}
		},
	},
};
