<template>
	<div />
</template>
<script>
import { S3_PATH } from '@/settings/Images';
import { admin } from '@/settings/Roles';
import { Ranks as ranksMessages } from '@/translations';
import Alert from '@/util/Alert';
import Ranks from '@/util/RankProgress';

export default {
	messages: [ranksMessages],
	data() {
		return {
			S3_PATH,
			admin,
			theme: process.env.VUE_APP_THEME,
			alert: new Alert(),
			ranks: new Ranks(),
			hasBeenRead: false,
		};
	},
	computed: {
		alerts() {
			try {
				return this.ranks.data.response.data.data;
			} catch (e) {
				return [];
			}
		},
	},
	mounted() {
		if (!this.admin.includes(this.$user.details().type)) {
			this.showPopup();
		} else {
			this.$emit('done');
		}
	},
	methods: {
		showPopup() {
			this.ranks.getAlerts().then(() => {
				const style = getComputedStyle(document.body);

				if (this.alerts.length !== 0) {
					const currentAlert = this.alerts[0].attributes;
					this.hasBeenRead = false;
					this.debounceTimeout();

					const rank = currentAlert.sub_rank || currentAlert.rank;
					let bodyMessage = this.translate('rank_alert_body', { new_rank: this.translate(rank) });

					if (!currentAlert.completed) {
						bodyMessage = `${bodyMessage} ${this.translate('rank_alert_disclaimer', { week_number: currentAlert.week_number })}`;
					}

					const config = {
						config: {
							allowEscapeKey: false,
							allowOutsideClick: false,
							showLoaderOnConfirm: true,
							confirmButtonColor: style.getPropertyValue('--primary'),
							confirmButtonText: this.translate('close'),
							imageUrl: `${this.S3_PATH}/assets/rank-alerts/${this.theme}/${rank}.png`,
						},
					};

					this.alert.confirmation(
						this.translate('rank_alert_title'),
						bodyMessage,
						config,
						true,
					).then(() => {
						this.$emit('done');
					}).catch(() => {
					}).finally(() => {
						this.markAsRead();
					});
				} else {
					this.$emit('done');
				}
			});
		},
		debounceTimeout() {
			setTimeout(this.markAsRead, 2000);
		},
		markAsRead() {
			if (!this.hasBeenRead) {
				this.ranks.updateAlert();
				this.hasBeenRead = true;
			}
		},
	},
};
</script>
