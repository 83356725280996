<template>
	<div class="h-100">
		<div class="row h-100">
			<div class="col mx-auto h-100">
				<div class="card mb-0 h-100">
					<div class="card-body">
						<div v-show="!loading">
							<div class="row">
								<div class="col">
									<h5 class="float-left">
										{{ translate('sales_by_country') }}
									</h5>
									<b-button
										:variant="!showFilters ? 'primary' : 'secondary'"
										class="float-right btn btn-sm bg-primary-alt mb-1"
										:class="showFilters ? 'active' : ''"
										@click="showFilters = !showFilters">
										<i
											class="fa"
											:class="!showFilters ? 'fa-filter' : 'fa-times'"
											aria-hidden="true" />
									</b-button>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<h6>{{ datePeriod }}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col position-relative">
									<GeoChart
										resolution="country"
										region="world"
										:data="salesData"
										:legend-options="showLegend ? legendOptions : {}" />
									<MapFilters
										v-show="showFilters"
										type="country"
										@submit="getDataFiltered"
										@clear="clear" />
								</div>
							</div>
						</div>
						<template v-if="loading">
							<div class="h-100 d-flex justify-content-center">
								<is-loading
									class="align-self-center"
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loading" />
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import GeoChart from '@/components/GeoChart';
import {
	DATE_RANGE_FILTER,
	DATE_RANGES,
	MDY_FORMAT,
} from '@/settings/Dates';
import {
	Dashboard as DashboardMessages,
	Countries,
	Report,
} from '@/translations';
import Dashboard from '@/util/Dashboard';
import MapFilters from './MapFilters';

export default {
	name: 'CountrySalesMap',
	components: {
		MapFilters,
		GeoChart,
	},
	messages: [Countries, DashboardMessages, Report],
	props: {
		showLegend: {
			type: Boolean,
			default: true,
		},
		userId: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {
			sales: new Dashboard(),
			showFilters: false,
			filters: {},
			defaultFilters: {
				[DATE_RANGE_FILTER]: DATE_RANGES.this_month,
			},
			rangeStart: '',
			rangeEnd: '',
			legendOptions: {
				numberFormat: '$,###,###,###.##',
			},
		};
	},
	computed: {
		datePeriod() {
			return this.getDatePeriod();
		},
		loading() {
			return !!this.sales.data.loading;
		},
		salesData() {
			const columnHeaders = ['Country', 'Value', { type: 'string', role: 'tooltip' }];

			try {
				const { data } = this.sales.data.response.data;
				const rows = data.reduce((result, sale) => {
					const attr = sale.attributes;

					if (attr.numeric_total > 0) {
						const text = `${this.translate('net_sales')}: ${attr.net_sales}`;
						// + `\n ${this.translate('total')}: ${attr.base_total}`
						// + `\n ${this.translate('subtotal')}: ${attr.base_subtotal}`
						// + `\n ${this.translate('refunds')}: ${attr.base_refund}`
						// + `\n ${this.translate('duties')}: ${attr.base_tax}`
						// + `\n ${this.translate('shipping')}: ${attr.base_shipping}`;

						result.push([
							{ v: attr.group, f: this.translate(attr.group.toLowerCase()) },
							attr.numeric_total,
							text,
						]);
					}

					return result;
				}, []);

				return [columnHeaders, ...rows];
			} catch (error) {
				return [columnHeaders];
			}
		},
	},
	mounted() {
		this.clear();
	},
	methods: {
		getDataFiltered(filters) {
			Object.keys(filters).forEach((key) => (filters[key] === null) && delete filters[key]);
			this.filters = filters;
			const handler = () => {
				const { start, end } = this.sales.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
			};
			if (this.userId > 0) {
				this.sales.getCountrySalesUser(this.filters, this.userId).then(handler);
			} else {
				this.sales.getCountrySales(this.filters).then(handler);
			}
			this.showFilters = false;
		},
		clear() {
			this.getDataFiltered(this.defaultFilters);
			this.showFilters = false;
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
