<template>
	<b-carousel
		v-model="slide"
		:interval="4000"
		controls
		indicators>
		<template
			v-for="image in images">
			<a
				v-if="image.hasUrl"
				:key="image.id"
				:href="image.url"
				target="_blank">
				<b-carousel-slide :img-src="image.src" />
			</a>
			<b-carousel-slide
				v-else
				:key="image.id"
				:img-src="image.src" />
		</template>
	</b-carousel>
</template>

<script>
export default {
	name: 'Carousel',
	props: {
		images: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
	data() {
		return {
			slide: 0,
		};
	},
};
</script>
<style>
.carousel-control-prev-icon {
	width: 30px;
	height: 30px;
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'><filter id='color-shadow' color-interpolation-filters='sRGB'><feComponentTransfer in='SourceGraphic'><feFuncR type='discrete' tableValues='0'/><feFuncG type='discrete' tableValues='0'/><feFuncB type='discrete' tableValues='0'/></feComponentTransfer><feGaussianBlur stdDeviation='.5'/><feOffset dx='0.1' dy='0.1' result='shadow'/><feComposite in='SourceGraphic' in2='shadow' operator='over'/></filter><g filter='url(%23color-shadow)'><path d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/></g></svg>") !important;
}

.carousel-control-next-icon {
	width: 30px;
	height: 30px;
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'><filter id='color-shadow' color-interpolation-filters='sRGB'><feComponentTransfer in='SourceGraphic'><feFuncR type='discrete' tableValues='0'/><feFuncG type='discrete' tableValues='0'/><feFuncB type='discrete' tableValues='0'/></feComponentTransfer><feGaussianBlur stdDeviation='.5'/><feOffset dx='0.1' dy='0.1' result='shadow'/><feComposite in='SourceGraphic' in2='shadow' operator='over'/></filter><g filter='url(%23color-shadow)'><path d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/></g></svg>") !important;
}

.carousel-indicators li{
	background-color: darkgray;
}
</style>
