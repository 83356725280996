<template>
	<div />
</template>
<script>
import { admin } from '@/settings/Roles';
import { Popups } from '@/translations';
import Popup from '@/util/Popup';

export default {
	name: 'Popups',
	messages: [Popups],
	data() {
		return {
			admin,
			alert: new this.$Alert(),
			showPopupsUtil: new Popup(),
			hidePopupsUtil: new Popup(),
			queue: [],
		};
	},
	computed: {
		userId() {
			return this.$user.details().id;
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			if (!this.admin.includes(this.$user.details().type)) {
				this.showPopupsUtil.getShowablePopups(this.userId).then((response) => {
					this.queue = response.map((popup) => ({
						id: popup.id,
						code_name: popup.attributes.code_name,
					}));
					this.showPopups();
				});
			} else {
				this.$emit('done');
			}
		},
		showPopups() {
			const initialQueueLength = this.queue.length;
			for (let i = 0; i < initialQueueLength; i += 1) {
				const popup = this.queue.shift();

				const trans = {
					title: this.translate(`${popup.code_name}_popup_title`).toUpperCase(),
					text: this.translate(`${popup.code_name}_popup_text`),
				};

				const options = {
					config: {
						icon: 'warning',
						confirmButtonText: this.translate('ok'),
						allowOutsideClick: false,
						allowEscapeKey: false,
						confirmButtonColor: '#eb772f',
						input: 'checkbox',
						customClass: {
							input: 'popup-input',
							actions: 'popup-actions',
						},
						inputPlaceholder: this.translate('dont_show_message_again'),
					},
				};

				this.alert.confirmation(trans.title, trans.text, options, true).then((result) => {
					if (result.value) {
						this.hidePopupsUtil.hidePopup(this.userId, popup.id);
					}
					this.attemptFinish();
				});
			}
			if (initialQueueLength === 0) {
				this.$emit('done');
			}
		},
		attemptFinish() {
			if (this.queue.length === 0) {
				this.$emit('done');
			}
		},
	},
};
</script>
<style>
/* These styles are not scoped due to sweet-alert-2 not having access to the vue-generated data- hash */
.popup-input {
	margin-top: 1.875em;
	margin-bottom: 0;
}
.popup-input span {
	font-size: 0.75em;
}
.popup-actions {
	margin-top: 0.75em;
}
</style>
