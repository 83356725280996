<template>
	<div
		v-if="!!Object.keys(periodInfoData).length || loading"
		class="row">
		<div class="col-md-12 col-lg-3">
			<totals
				v-if="!loading"
				:header-title="translate('daily_sales')"
				:show-abbreviation="true"
				:abbreviate-since="window.width <= deviceResolutions.ipad.width ? 5 : 7"
				:big-label="periodInfoData.daily_sales.amount_value.toString()"
				:big-formatted-label="periodInfoData.daily_sales.amount"
				:icon="'fas fa-dollar-sign'" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div class="col-md-12 col-lg-3">
			<totals
				v-if="!loading"
				:header-title="translate('weekly_sales')"
				:show-abbreviation="true"
				:abbreviate-since="window.width <= deviceResolutions.ipad.width ? 5 : 7"
				:big-label="periodInfoData.weekly_sales.amount_value.toString()"
				:big-formatted-label="periodInfoData.weekly_sales.amount"
				:icon="'fas fa-dollar-sign'" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div class="col-md-12 col-lg-3">
			<totals
				v-if="!loading"
				:header-title="translate('monthly_sales')"
				:show-abbreviation="true"
				:abbreviate-since="window.width <= deviceResolutions.ipad.width ? 5 : 7"
				:big-label="periodInfoData.monthly_sales.amount_value.toString()"
				:big-formatted-label="periodInfoData.monthly_sales.amount"
				:icon="'fas fa-dollar-sign'" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
		<div class="col-md-12 col-lg-3">
			<totals
				v-if="!loading"
				:header-title="translate('monthly_refunds')"
				:show-abbreviation="true"
				:abbreviate-since="window.width <= deviceResolutions.ipad.width ? 5 : 7"
				:big-label="periodInfoData.monthly_refunds.amount_value.toString()"
				:big-formatted-label="periodInfoData.monthly_refunds.amount"
				:icon="'fas fa-dollar-sign'" />
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading" />
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import { DEFAULT_CURRENCY_ICON } from '@/settings/Icons';
import { deviceResolutions } from '@/settings/Screen';
import Dashboard from '@/util/Dashboard';
import WindowResolution from '@/mixins/WindowResolution';
import Totals from '@/components/Cards/CardWidgetRegular';

export default {
	name: 'PeriodTotalsInfo',
	messages: [DashboardTranslations],
	components: {
		Totals,
	},
	mixins: [WindowResolution],
	props: {
		options: {
			type: Object,
			default() {
				return {};
			},
		},
		userId: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {
			periodInfo: new Dashboard(),
			defaultCurrencyIcon: DEFAULT_CURRENCY_ICON,
			deviceResolutions,
		};
	},
	computed: {
		loading() {
			return !!this.periodInfo.dataFiltered.loading;
		},
		periodInfoData() {
			try {
				const { response } = this.periodInfo.dataFiltered.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		if (this.userId > 0) {
			this.periodInfo.getPeriodInfoUser(undefined, this.userId);
		} else {
			this.periodInfo.getPeriodInfo();
		}
	},
};
</script>
