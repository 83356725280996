<template>
	<div class="d-flex notification-container">
		<div>
			<div
				class="d-flex justify-content-center"
				style="height: 100%">
				<h5
					class="text-center custom-icon"
					:class="['xs', 'sm'].includes(windowWidth) ? 'icon-small' : 'icon-large'"
					:style="'background-color:' + iconCircleColor">
					<i
						:class="iconClass"
						style="margin: 5px;" />
				</h5>
			</div>
		</div>
		<div
			class="flex-fill"
			style="width: 85%; padding-left: 15px">
			<div
				class="row"
				style="width: 100%;">
				<div
					class="col d-flex justify-content-between mt-1"
					style="width: 100%">
					<div>
						<small class="text-medium-emphasis">{{ ['xs', 'sm'].includes(windowWidth) ? user.id : formatUser }}</small>
					</div>
					<div>
						<small class="text-medium-emphasis">{{ timeAgo(createdAt) }}</small>
					</div>
				</div>
			</div>
			<div
				v-if="['xs', 'sm'].includes(windowWidth)"
				class="row"
				style="width: 100%;">
				<div class="col text-truncate">
					<small class="text-medium-emphasis">{{ user.username }}</small>
				</div>
			</div>
			<div
				class="row"
				style="width: 100%;">
				<div class="col">
					<h5 class="font-weight-bold mb-0">
						{{ title }}
					</h5>
				</div>
			</div>
			<div
				class="row"
				style="width: 100%;">
				<div class="col">
					<p class="text-wrap text-medium-emphasis mb-1">
						{{ description }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {
	OrganizationNotifications as OrganizationNotificationsMessages,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import {
	USE_USERNAME as actionsNeedsCauserUsername,
	NOTIFICATION_ICON as notificationIcon,
	VISITOR_ENROLLMENT as visitorEnrollment,
} from '@/settings/OrganizationNotifications';

export default {
	name: 'OrganizationNotificationItem',
	messages: [OrganizationNotificationsMessages],
	mixins: [WindowSizes],
	props: {
		createdAt: {
			type: Object,
			default: () => ({}),
		},
		user: {
			type: Object,
			default: () => ({}),
		},
		causer: {
			type: Object,
			default: () => ({}),
		},
		codeName: {
			type: String,
			default: '',
		},
		options: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			actionsNeedsCauserUsername,
			notificationIcon,
			visitorEnrollment,
		};
	},
	computed: {
		title() {
			const title = this.translate(this.codeName, this.options);
			return title.length > 0 ? `${title[0].toUpperCase()}${title.substr(1)}` : title;
		},
		description() {
			const opts = this.options;
			if (this.actionsNeedsCauserUsername.includes(this.codeName)) {
				opts.causer = this.formatCauser;
			}
			// eslint-disable-next-line camelcase
			if (this.codeName === this.visitorEnrollment && (opts?.bvs || 0) === 0 && typeof opts?.order_id !== 'undefined') {
				return this.translate(this.codeName.concat('_order_description'), opts);
			}
			return this.translate(this.codeName.concat('_description'), opts);
		},
		formatUser() {
			return `${this.user.id} ${this.user.username}`;
		},
		formatCauser() {
			return `${this.causer.id} ${this.causer.username}`;
		},
		iconClass() {
			let { icon } = this.notificationIcon.default;

			if (typeof this.notificationIcon[this.codeName] !== 'undefined' && typeof this.notificationIcon[this.codeName].icon !== 'undefined') {
				icon = this.notificationIcon[this.codeName].icon;
			}

			return icon.concat(' text-white fa-sm');
		},
		iconCircleColor() {
			if (typeof this.notificationIcon[this.codeName] !== 'undefined') {
				return this.notificationIcon[this.codeName].color;
			}

			return this.notificationIcon.default.color;
		},
	},
	methods: {
		timeAgo(created) {
			return this.$moment.tz(created.date, created.timezone).fromNow();
		},
	},
};
</script>
<style scoped>
	.notification-container {
		width: 100%;
		border-bottom: rgba(0,0,0,0.16) solid;
		border-bottom-width: 0.5px
	}

	.custom-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: center;
		margin-left: 15px;
		border-radius: 100%;
		background: blue;
	}

	.icon-small {
		width: 2rem;
		height: 2rem;
		font-size: 1rem;
	}

	.icon-large {
		width: 2.24rem;
		height: 2.24rem;
		font-size: 1.19rem;
	}

</style>
