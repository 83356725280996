<template>
	<div class="no-gutters justify-content-end">
		<slot
			name="hover-element">
			<div
				:id="`popover-pvb-${hoverId}`"
				class="pointer drb-pbv-popover-trigger"
				tabindex="0">
				<i class="btn-link text-center">{{ orders.length }}</i>
			</div>
		</slot>
		<b-popover
			:target="`popover-pvb-${hoverId}`"
			triggers="hover"
			custom-class="drb-pbv-popover"
			boundary-padding="0"
			:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'right'">
			<b-alert
				class="p-2 rounded-0 mb-1 border-bottom"
				show
				variant="secondary-darker">
				<div class="row no-gutters">
					<div class="col">
						<h6
							class="mb-0">
							{{ translate("orders_qty") }}
						</h6>
					</div>
				</div>
			</b-alert>
			<div class="p-1 pb-2">
				<template>
					<div class="pbv-popover">
						<div
							v-if="true"
							class="table-responsive mb-0">
							<table class="table text-nowrap">
								<thead>
									<tr class="text-center">
										<th class="p-2 text-center">
											{{ '#' }}
										</th>
										<th class="p-2 text-center">
											{{ translate('week') }}
										</th>
										<th class="p-2 text-left">
											{{ translate('order_id') }}
										</th>
										<th class="p-2 align-middle">
											{{ translate('user_id') }}
										</th>
										<th class="p-2 align-middle">
											{{ translate('bv') }}
										</th>
										<th
											class="p-2 align-middle">
											{{ translate('package') }}
										</th>
										<th class="p-2 align-middle">
											{{ translate('earning') }}
										</th>
										<th class="p-2 align-middle">
											{{ translate('total_usd') }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in orders"
										:key="index">
										<td class="p-2 text-center">
											{{ index + 1 }}
										</td>
										<td class="p-2 text-center">
											{{ item.week_number }}
										</td>
										<td class="p-2 text-center">
											{{ item.order_id }}
										</td>
										<td class="p-2 text-left">
											<span class="badge badge-primary">{{ item.from_user_id }}</span>
										</td>
										<td class="p-2 align-middle text-right">
											{{ item.order_volume }}
										</td>
										<td
											class="p-2 align-middle text-right">
											{{ translate(item.user_package) }}
										</td>
										<td class="p-2 align-middle text-right">
											{{ item.earning }}
										</td>
										<td class="p-2 align-middle text-right">
											{{ item.amount }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</template>
			</div>
		</b-popover>
	</div>
</template>
<script>
import {
	Grids, Promotions as PromoMessages, Packages,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'Orders',
	messages: [Grids, PromoMessages, Packages],
	mixins: [WindowSizes],
	props: {
		orders: {
			type: Array,
			default: () => [],
		},
		hoverId: {
			type: String,
			default: '',
		},
	},
};
</script>
<style>
.drb-pbv-popover-trigger:focus {
	outline: none;
}
.drb-pbv-popover {
	border-radius: 0;
	max-width: 500px;
}
.drb-pbv-popover > .popover-body {
	padding: 0;
	max-width: 500px;
}
.pbv-popover {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
}
.pbv-popover::-webkit-scrollbar {
    width: 5px;
}
.pbv-popover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.pbv-popover::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear,height .2s linear,width .2s ease-in-out,border-radius .2s ease-in-out;
    right: 2px;
    width: 6px
}
</style>
