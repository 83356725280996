<template>
	<div v-if="hasData">
		<div class="row">
			<div class="col">
				<div class="card mb-0">
					<div class="card-header bg-dark">
						<h5 class="mb-0">
							{{ translate('weekly_volume_history') }}
						</h5>
					</div>
					<div class="card-body p-0">
						<div class="table-responsive">
							<table class="table table-hover table-striped text-center text-nowrap">
								<thead>
									<tr>
										<th
											v-if="windowWidth === 'xs' "
											class="border-top-0">
											{{ translate('start_end') }}
										</th>
										<th
											v-else
											class="border-top-0">
											{{ translate('period_start_end') }}
										</th>
										<th class="border-top-0">
											{{ translate('left_volume') }}
										</th>
										<th class="border-top-0">
											{{ translate('right_volume') }}
										</th>
										<th class="border-top-0">
											{{ translate('left_carryover') }}
										</th>
										<th class="border-top-0">
											{{ translate('right_carryover') }}
										</th>
										<th class="border-top-0">
											{{ translate('left_total') }}
										</th>
										<th class="border-top-0">
											{{ translate('right_total') }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in volumeHistoryData"
										:key="index">
										<td
											v-if="windowWidth === 'xs' "
											class="align-middle">
											<span class="text-capitalize font-weight-bold">{{ `${translate('week_number', {number: item.attributes.week_number})} ` }}</span>
										</td>
										<td
											v-else
											class="align-middle">
											<span class="text-capitalize font-weight-bold">{{ `${translate('week_number', {number: item.attributes.week_number})} ` }}</span>{{ `(${$moment(item.attributes.start_date.date).format(dateFormat)}` }} {{ translate('to') }} {{ `${ $moment(item.attributes.end_date.date).format(dateFormat)})` }}
										</td>
										<td class="align-middle">
											{{ item.attributes.left_bv }}
										</td>
										<td class="align-middle">
											{{ item.attributes.right_bv }}
										</td>
										<td class="align-middle">
											{{ item.attributes.carry_left_bv }}
										</td>
										<td class="align-middle">
											{{ item.attributes.carry_right_bv }}
										</td>
										<td class="align-middle">
											{{ item.attributes.total_left_bv }}
										</td>
										<td class="align-middle">
											{{ item.attributes.total_right_bv }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import Dashboard from '@/util/Dashboard';
import { YMD_FORMAT } from '@/settings/Dates';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'VolumeHistory',
	components: {
	},
	mixins: [WindowSizes],
	messages: [DashboardTranslations],
	data() {
		return {
			valumeHistory: new Dashboard(),
			dateFormat: YMD_FORMAT,
		};
	},
	computed: {
		volumeHistoryData() {
			try {
				const { data } = this.valumeHistory.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		userId() {
			const { distributorId } = this.$route.params;
			if (typeof distributorId !== 'undefined') {
				return distributorId;
			}
			return this.$user.details().id;
		},
		hasData() {
			const response = this.volumeHistoryData.length;
			return !!response;
		},
	},
	mounted() {
		if (Object.keys(this.$user.details()).length > 0) {
			this.valumeHistory.getVolumeHistory(this.userId);
		}
	},
};
</script>
