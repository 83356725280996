<template>
	<div />
</template>
<script>
import { S3_PATH } from '@/settings/Images';
import { admin } from '@/settings/Roles';
import { Wallets } from '@/translations';
import Alert from '@/util/Alert';
import Wallet from '@/util/Wallets';

export default {
	messages: [Wallets],
	data() {
		return {
			S3_PATH,
			admin,
			theme: process.env.VUE_APP_THEME,
			alert: new Alert(),
			wallet: new Wallet(),
			hasBeenRead: false,
		};
	},
	computed: {
		alerts() {
			try {
				return this.wallet.data.response.data.data;
			} catch (e) {
				return [];
			}
		},
	},
	mounted() {
		if (!this.admin.includes(this.$user.details().type)) {
			this.showAlert();
		} else {
			this.$emit('done');
		}
	},
	methods: {
		showAlert() {
			this.wallet.getAlerts().then(() => {
				const style = getComputedStyle(document.body);

				if (this.alerts.length !== 0) {
					this.hasBeenRead = false;
					this.debounceTimeout();
					const config = {
						config: {
							allowEscapeKey: false,
							allowOutsideClick: false,
							showLoaderOnConfirm: true,
							showCancelButton: true,
							cancelButtonColor: style.getPropertyValue('--primary'),
							confirmButtonColor: style.getPropertyValue('--gray'),
							confirmButtonText: this.translate('go_to_wallet'),
							cancelButtonText: this.translate('close'),
							imageUrl: `${this.S3_PATH}/assets/wallet-alerts/${this.theme}/commission.png`,
						},
					};
					const category = this.alerts[0].attributes.category_code_name;
					this.alert.confirmation(
						this.translate(`${category}_alert_title`),
						this.translate(`${category}_alert_message`, {
							companyName: process.env.VUE_APP_TITLE,
							username: this.$user.details().username,
						}),
						config,
						true,
					).then(() => {
						this.$router.push({ name: 'Wallets' });

						// TO DO: when 'Close' is clicked, pop current alert from stack and run show Alert again
						//  use argument from this context to determine which button was clicked
					}).catch(() => {
					}).finally(() => {
						this.markAsRead();
						this.$emit('done');
					});
				} else {
					this.$emit('done');
				}
			});
		},
		debounceTimeout() {
			setTimeout(this.markAsRead, 2000);
		},
		markAsRead() {
			if (!this.hasBeenRead) {
				this.wallet.updateAlert(this.alerts[0].id);
				this.hasBeenRead = true;
			}
		},
	},
};
</script>
