import { GET_SHOWABLE_POPUPS, GET_SHOWABLE_MEDIA_POPUPS, HIDE_POPUP } from '@/config/endpoint';
import Req from './AxiosRequest';

const axios = new Req();
class Popup {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
	}

	clear() {
		this.data.clear();
	}

	getShowablePopups(userId, options) {
		const { method, endpoint } = GET_SHOWABLE_POPUPS;
		return axios[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getMediaPopups(userId) {
		const { method, endpoint } = GET_SHOWABLE_MEDIA_POPUPS;
		return axios[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	hidePopup(userId, popupId) {
		const { method, endpoint } = HIDE_POPUP;
		return axios[method](endpoint(userId, popupId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Popup;
