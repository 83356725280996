<!-- eslint-disable vue/no-v-html -->
<template>
	<div v-if="userId">
		<div class="border rounded p-2 mb-4">
			<div class="row mt-3 mb-2">
				<div class="col">
					<period-totals-info
						:options="options"
						:user-id="userId" />
				</div>
			</div>
		</div>
		<div
			class="row mb-4 dashboard-map-row">
			<div
				:class="['xs','sm'].includes(windowWidth) ? 'mb-4 h-50' : 'h-100'"
				class="col-md-6 dashboard-map-col">
				<regional-sales-map
					:show-legend="!['xs','sm'].includes(windowWidth)"
					:user-id="userId" />
			</div>
			<div
				:class="['xs','sm'].includes(windowWidth) ? 'mb-4 h-50' : 'h-100'"
				class="col-md-6 dashboard-map-col">
				<country-sales-map
					:show-legend="!['xs','sm'].includes(windowWidth)"
					:user-id="userId" />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<income-totals-graph :user-id="userId" />
			</div>
		</div>
	</div>
</template>

<script>
import { Dashboard as messages } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import PeriodTotalsInfo from '@/views/AdminDashboard/components/PeriodTotalsInfo.vue';
import CountrySalesMap from '@/views/AdminDashboard/components/CountrySalesMap.vue';
import RegionalSalesMap from '@/views/AdminDashboard/components/RegionalSalesMap.vue';
import IncomeTotalsGraph from '@/views/AdminDashboard/components/IncomeTotalsGraph.vue';

export default {
	name: 'AgencyDashboard',
	messages,
	components: {
		IncomeTotalsGraph,
		RegionalSalesMap,
		CountrySalesMap,
		PeriodTotalsInfo,
	},
	mixins: [WindowSizes],
	data() {
		return {
			options: {},
			userId: null,
		};
	},
	mounted() {
		// It means that a corporate user is trying to see an agency's dashboard
		if (this.$route.params.userId) {
			this.userId = this.$route.params.userId;
		} else { // It means that an agency is trying to see his own dashboard
			this.userId = this.$user.details().id;
		}
	},
};
</script>
