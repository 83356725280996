<template>
	<div class="w-100 h-75">
		<div class="w-100 h-75 pb-3">
			<div class="text-center mt-2 pt-1 text-uppercase text-primary">
				<b>{{ translate ('permanent_rank_subtitle') }}</b>
			</div>
			<b-row class="no-gutters d-flex align-items-center justify-content-center mt-1">
				<!-- Image Column -->
				<b-col cols="auto">
					<img
						:src="`${S3_PATH}/assets/ranks/${themeName}/${permanentRank}_90x90.png`"
						alt="rank"
						class="img-fluid">
				</b-col>

				<!-- Text Column - fits content -->
				<b-col
					cols="auto"
					class="pl-3 text-left">
					<div
						:style="{
							fontSize: language === 'ja' ? '40px' : (permanentRank.length < 9 ? '60px' : '50px')
						}">
						{{ translate(permanentRank) }}
					</div>

					<div
						class="text-muted text-bold text-capitalize"
						style="margin-top: -15px">
						{{ translate('achieved_week') }}: <b>{{ translate('week_number', { number: permanentRankWeek }) }}</b>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { Ranks } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'PermanentRank',
	messages: [Ranks],
	mixins: [WindowSizes],
	props: {
		permanentRank: {
			type: String || null,
			default: null,
		},
		permanentRankWeek: {
			type: Number || null,
			default: null,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
};
</script>
