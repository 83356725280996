<template>
	<div />
</template>
<script>
import { admin } from '@/settings/Roles';
import { Dashboard as DashboardMessages } from '@/translations';
import Dashboard from '@/util/Dashboard';

export default {
	messages: [DashboardMessages],
	data() {
		return {
			admin,
			alert: new this.$Alert(),
			showIntroPopup: new Dashboard(),
			hideIntroPopup: new Dashboard(),
		};
	},
	mounted() {
		if (!this.admin.includes(this.$user.details().type)) {
			this.showIntroPopup.canShowIntroPopup().then((response) => {
				this.showPopup(response.response.show_intro_popup);
			});
		} else {
			this.$emit('done');
		}
	},
	methods: {
		showPopup(show) {
			if (show) {
				const trans = {
					title: this.translate('important_note').toUpperCase(),
					text: this.translate('distributor_intro_popup_text'),
				};
				const options = {
					config: {
						icon: 'warning',
						confirmButtonText: this.translate('ok'),
						allowOutsideClick: false,
						allowEscapeKey: false,
						confirmButtonColor: '#eb772f',
						input: 'checkbox',
						customClass: {
							input: 'intro-popup-input',
							actions: 'intro-popup-actions',
						},
						inputPlaceholder: this.translate('dont_show_message_again'),
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then((result) => {
					if (result.value) {
						this.hideIntroPopup.hideIntroPopup();
					}
					this.$emit('done');
				});
			} else {
				this.$emit('done');
			}
		},
	},
};
</script>
<style>
/* These styles are not scoped due to sweet-alert-2 not having access to the vue-generated data- hash */
.intro-popup-input {
	margin-top: 1.875em;
	margin-bottom: 0;
}
.intro-popup-input span {
	font-size: 0.75em;
}
.intro-popup-actions {
	margin-top: 0.75em;
}
</style>
