<template>
	<div class="h-100">
		<div class="row h-100">
			<div class="col mx-auto h-100">
				<div class="card mb-0 h-100">
					<div class="card-body">
						<div v-show="!loading">
							<div class="row">
								<div class="col">
									<h5 class="float-left">
										{{ translate('sales_by_state') }}
									</h5>
									<b-button
										:variant="!showFilters ? 'primary' : 'secondary'"
										class="float-right btn btn-sm bg-primary-alt mb-1"
										:class="showFilters ? 'active' : ''"
										@click="showFilters = !showFilters">
										<i
											class="fa"
											:class="!showFilters ? 'fa-filter' : 'fa-times'"
											aria-hidden="true" />
									</b-button>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<h6>{{ datePeriod }}</h6>
								</div>
							</div>
							<div class="row">
								<div class="col position-relative">
									<GeoChart
										:region="region"
										:data="salesData"
										:legend-options="showLegend ? legendOptions : {}"
										:color-axis="colorAxis" />
									<MapFilters
										v-show="showFilters"
										type="regional"
										@submit="getDataFiltered"
										@clear="clear" />
								</div>
							</div>
						</div>
						<template v-if="loading">
							<div class="h-100 d-flex justify-content-center">
								<is-loading
									class="align-self-center"
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loading" />
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import GeoChart from '@/components/GeoChart';
import { DEFAULT_COUNTRY } from '@/settings/Country';
import {
	DATE_RANGE_FILTER,
	DATE_RANGES,
	MDY_FORMAT,
} from '@/settings/Dates';
import { Dashboard as DashboardMessages, Report } from '@/translations';
import Dashboard from '@/util/Dashboard';
import MapFilters from './MapFilters';

const defaultFilters = {
	[DATE_RANGE_FILTER]: DATE_RANGES.this_month,
};

export default {
	name: 'RegionalSalesMap',
	components: {
		MapFilters,
		GeoChart,
	},
	messages: [DashboardMessages, Report],
	props: {
		showLegend: {
			type: Boolean,
			default: true,
		},
		userId: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {
			sales: new Dashboard(),
			showFilters: false,
			filters: {},
			defaultFilters: {
				[DATE_RANGE_FILTER]: DATE_RANGES.this_month,
			},
			rangeStart: '',
			rangeEnd: '',
			legendOptions: {
				numberFormat: '$,###,###,###.##',
			},
		};
	},
	computed: {
		datePeriod() {
			return this.getDatePeriod();
		},
		loading() {
			return !!this.sales.data.loading;
		},
		salesData() {
			const columnHeaders = ['Province', 'Value', { type: 'string', role: 'tooltip' }];

			try {
				const { data } = this.sales.data.response.data;
				const rows = data.map((sale) => {
					const attr = sale.attributes;
					const text = `${this.translate('net_sales')}: ${attr.net_sales}`;

					return [
						{ v: sale.id, f: attr.region_name },
						attr.numeric_net_sales,
						text,
					];
				});

				return [columnHeaders, ...rows];
			} catch (error) {
				return [columnHeaders];
			}
		},
		region() {
			try {
				const { meta } = this.sales.data.response.data;
				return meta.country;
			} catch (error) {
				return DEFAULT_COUNTRY;
			}
		},
		colorAxis() {
			try {
				const { meta } = this.sales.data.response.data;
				if (meta.total_sales <= 0) {
					return {
						colors: [
							'#f0f3f5', // gray
						],
					};
				}

				return {
					colors: [
						'#f0f3f5', // gray
						'#fcd01f', // from yellow
						'#fbc820',
						'#f9c322',
						'#f8bc24',
						'#f6b726',
						'#f4b027',
						'#f3ac29',
						'#f1a52b',
						'#f0a22c',
						'#ee9b2e',
						'#ec972f',
						'#eb9131',
						'#e98e32',
						'#e88834',
						'#e68535',
						'#e47f37',
						'#e37c38',
						'#e17739',
						'#df743b',
						'#de6f3c', // to orange
					],
				};
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.clear();
	},
	methods: {
		getDataFiltered(filters) {
			Object.keys(filters).forEach((key) => (filters[key] === null) && delete filters[key]);
			this.filters = filters;
			const handler = () => {
				const { start, end } = this.sales.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
			};
			if (this.userId > 0) {
				this.sales.getRegionalSalesUser(this.filters, this.userId).then(handler);
			} else {
				this.sales.getRegionalSales(this.filters).then(handler);
			}
			this.showFilters = false;
		},
		clear() {
			this.getDataFiltered(defaultFilters);
			this.showFilters = false;
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
