<template>
	<GChart
		:settings="{ packages: ['geochart']}"
		type="GeoChart"
		:data="data"
		:resize-debounce="500"
		:options="chartOptions" />
</template>

<script>
/*
References and examples for this component's data prop usage:
- https://developers.google.com/chart/interactive/docs/gallery/geochart#regions-mode-format
- http://cmoreira.net/interactive-maps-generator/examples.html
- https://github.com/devstark-com/vue-google-charts
*/

import { GChart } from 'vue-google-charts';

export default {
	components: {
		GChart,
	},
	props: {
		region: {
			type: String,
			default: '',
		},
		data: {
			type: Array,
			default: () => [],
		},
		resolution: {
			type: String,
			default: '',
		},
		defaultColor: {
			type: String,
			default: '',
		},
		legendOptions: {
			type: Object,
			default: () => ({}),
		},
		colorAxis: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		chartOptions() {
			const defaultRegionColor = getComputedStyle(document.body).getPropertyValue('--light').trim();

			const style = getComputedStyle(document.body);
			const defaultColorAxis = {
				colors: [
					style.getPropertyValue('--warning').trim(),
					style.getPropertyValue('--primary').trim(),
				],
			};

			return {
				region: this.region ? this.region : this.country,
				displayMode: 'regions',
				resolution: this.resolution ? this.resolution : 'provinces',
				colorAxis: Object.keys(this.colorAxis).length ? this.colorAxis : defaultColorAxis,
				legend: this.legendOptions,
				datalessRegionColor: this.defaultColor ? this.defaultColor : defaultRegionColor,
			};
		},
	},
};
</script>
