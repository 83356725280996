<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row col-12 col-md-12 no-gutters justify-content-center">
		<div>
			<!-- Qualification progress -->
			<div
				v-if="isStillQualifying"
				class="py-2">
				<div class="row p-custom">
					<b-alert
						show
						variant="secondary-darker"
						class="col-12">
						<div class="row">
							<div class="col-12 text-center mb-3">
								<span
									:title="translate('current_qualification')"
									class="h6 font-weight-bold mb-0">
									{{ translate('associate_qualification') }}
								</span>
								<br>
								<small class="text-muted text-uppercase font-weight-bold">{{ translate(associate ? 'qualified' : 'not_qualified') }}</small>
							</div>
							<div class="col-12 text-center mb-2">
								<img
									:src="require(`@/assets/images/themes/${themeName}/qualified/${associate ? 'fully_qualified' : 'not_fully_qualified'}.png`)"
									alt="">
							</div>
						</div>
					</b-alert>
				</div>
				<div class="row px-3">
					<div class="col text-center">
						<span class="mb-2">{{ translate('team_bonus_cycle') }}</span>
						<b-progress
							:max="minCycles"
							animated
							striped>
							<b-progress-bar
								:value="cycles"
								:class="cycles < minCycles ? 'bg-primary' : 'bg-lime'" />
							<span
								:class="cycles < minCycles / 2 ? 'text-dark' : 'text-white'"
								class="text-center w-100 pr-4"
								style="position: absolute;">
								{{ `${cycles}/${minCycles}` }}
							</span>
						</b-progress>
					</div>
				</div>
			</div>
			<!-- Qualified -->
			<div
				v-if="qualified">
				<div class="row px-3 pb-2 justify-content-center">
					<div class="col-auto">
						<img
							:src="`${S3_PATH}/assets/ranks/${themeName}/rising_star_56x56_orange.png`">
					</div>
				</div>
				<div
					v-if="qualified"
					class="row px-3">
					<div class="col-12">
						<div class="text-center">
							<div
								class="mb-0"
								v-html="applyAttrsToHtmlString(translate('rising_star_title'), qualifiedTitleStyles)" />
							<hr class="my-2 py-1">
							<b-alert
								variant="green"
								class="py-2 mb-0 my-0"
								show>
								<span v-html="applyAttrsToHtmlString(translate('rising_star_description'), qualifiedDescriptionStyles)" />
							</b-alert>
						</div>
					</div>
				</div>
			</div>
			<!-- Did not qualify -->
			<div
				v-if="didNotQualify && showAmnestyPromo"
				class="py-2">
				<div class="row justify-content-center px-3">
					<div class="col-auto">
						<b>{{ translate('amnesty_period') }}</b>: <span class="text-primary">{{ amnestyPeriodText() }}</span>
					</div>
				</div>
				<hr class="my-2 py-1">
				<div class="row justify-content-center px-3 mb-3">
					<div
						v-if="amnestyPeriodEnded"
						class="col text-center">
						<b-alert
							class="mb-0"
							show
							variant="danger">
							<p class="mb-0 text-dark">
								{{ translate("rising_star_bonus_extension_description") }}:
							</p>
							<span class="text-dark"><b>{{ amnestyExtensionPeriodText() }}</b></span>
						</b-alert>
					</div>
				</div>
				<div class="row p-custom">
					<b-alert
						show
						variant="secondary-darker"
						class="col-12">
						<div class="row">
							<div class="col-12 text-center mb-3">
								<span
									:title="translate('current_qualification')"
									class="h6 font-weight-bold mb-0">
									{{ translate('associate_qualification') }}
								</span>
								<br>
								<small class="text-muted text-uppercase font-weight-bold">{{ translate(associate ? 'qualified' : 'not_qualified') }}</small>
							</div>
							<div class="col-12 text-center mb-2">
								<img
									:src="require(`@/assets/images/themes/${themeName}/qualified/${associate ? 'fully_qualified' : 'not_fully_qualified'}.png`)"
									alt="">
							</div>
						</div>
					</b-alert>
				</div>
				<div
					v-if="!amnestyPeriodEnded"
					class="row px-3 mb-3">
					<div
						class="col text-center">
						<span class="mb-2">{{ translate('pbv') }}</span>
						<b-progress
							:max="minPbv"
							animated
							striped>
							<b-progress-bar
								:value="pbv"
								:class="pbv < minPbv ? 'bg-primary' : 'bg-lime'" />
							<span
								:class="pbv < minPbv / 2 ? 'text-dark' : 'text-white'"
								class="text-center w-100 pr-4"
								style="position: absolute;">
								{{ `${pbv}/${minPbv}` }}
							</span>
						</b-progress>
					</div>
				</div>
				<div class="row px-3 mb-1">
					<div class="col text-center">
						<span class="mb-2">{{ translate('enrolled_rising_stars') }}</span>
						<div class="d-flex justify-content-center mt-2">
							<div class="col-auto">
								<img :src="amnestyPromo.enrolled_rising_stars > 0 ? userGreenImage : userGrayImage">
							</div>
							<div class="col-auto">
								<img :src="amnestyPromo.enrolled_rising_stars > 1 ? userGreenImage : userGrayImage">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	Dashboard as DashboardMessages,
	Ranks,
	Tooltip,
	AccountInformation,
	Promotions,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { MMMD_FORMAT, MD_FORMAT } from '@/settings/Dates';
import { RISING_STAR_DETAILS_URL } from '@/settings/Ranks';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'RisingStarDetails',
	messages: [DashboardMessages, Ranks, Tooltip, AccountInformation, Promotions],
	mixins: [WindowSizes],
	props: {
		associate: {
			type: Boolean,
			required: true,
		},
		qualified: {
			type: Boolean,
			required: true,
		},
		minCycles: {
			type: Number,
			required: true,
		},
		cycles: {
			type: Number,
			required: true,
		},
		qualifyingPeriod: {
			type: Object,
			required: true,
		},
		showAmnestyPromo: {
			type: Boolean,
			default: true,
		},
		amnestyPromo: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			infoLink: RISING_STAR_DETAILS_URL,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		isStillQualifying() {
			return !this.qualified && !this.qualifyingPeriod.ended;
		},
		didNotQualify() {
			return !this.qualified && this.qualifyingPeriod.ended;
		},
		qualifiedTitleStyles() {
			return {
				h5: { class: 'mb-0' },
				b: {
					class: 'font-weight-bolder',
					style: { paddingRight: '0.1em' },
				},
				p: { class: 'font-weight-bold text-primary text-uppercase mb-2' },
			};
		},
		qualifiedDescriptionStyles() {
			return {
				p: { class: 'h6 mb-0' },
				span: { class: 'd-inline-block' },
			};
		},
		userGrayImage() {
			return require('@/assets/images/common/user_gray_30.png'); // eslint-disable-line global-require
		},
		userGreenImage() {
			return require('@/assets/images/common/user_green_30.png'); // eslint-disable-line global-require
		},
		amnestyPeriodEnded() {
			return this.amnestyPromo.qualify_period.ended;
		},
		pbv() {
			return this.amnestyPromo.pbv;
		},
		minPbv() {
			return this.amnestyPromo.min_pbv;
		},
	},
	methods: {
		applyAttrsToHtmlString,
		qualifyingPeriodText() {
			const start = this.$moment(this.qualifyingPeriod.start).format(MMMD_FORMAT);
			const end = this.$moment(this.qualifyingPeriod.end).format(MMMD_FORMAT);
			return `${start} - ${end}`;
		},
		amnestyPeriodText() {
			const start = this.$moment(this.amnestyPromo.qualify_period.start).format(MMMD_FORMAT);
			const end = this.$moment(this.amnestyPromo.qualify_period.end).format(MMMD_FORMAT);
			return `${start} - ${end}`;
		},
		amnestyExtensionPeriodText() {
			const end = this.$moment(this.amnestyPromo.qualify_period.extension_end_date).format(MD_FORMAT);
			return `${end}`;
		},
	},
};
</script>
<style>
/* Not scoped to be able to access the b-popover internal elements */
.rising-star-popover > .popover-body {
	padding: 0;
}
.rising-star-popover .font-weight-bolder {
	font-weight: 800 !important;
}
.bg-lime {
	background-color: #8bc878;
}
</style>
<style scoped>
.rising-star-popover {
	border-radius: 0;
	min-width: 320px;
	width: 25vw;
	max-width: 100%;
}
.rising-star-popover-trigger:focus {
	outline: none;
}
.p-custom {
	padding-left: 2rem;
	padding-right: 2rem;
}
</style>
