<template>
	<div
		class="row">
		<div class="col">
			<div
				:class="(darkTheme ? 'card ' : '').concat('mb-0')">
				<div
					:class="(darkTheme ? 'bg-dark d-flex align-items-center' : ' ').concat('p-3')">
					<div class="row mt-2">
						<div
							:class="windowWidth === 'md' ? 'col-sm-7' : 'col-sm-6'"
							class="col-12">
							<a
								:href="replicatedSite"
								target="_blank">
								{{ replicatedSite }}
							</a>
							<div class="text-light text-uppercase font-weight-bold">
								{{ translate('replicated_site') }}
								<b-button
									v-if="isDistributor"
									variant="transparent"
									class="p-0 ml-1 text-white"
									@click="$router.push({ name: 'ReplicatedSite' })">
									<i class="fas fa-cog btn-primary p-1 rounded" />
								</b-button>
							</div>
							<div
								v-if="copied"
								class="text-secondary small">
								{{ translate('copied') }}
							</div>
						</div>
						<div
							:class="windowWidth === 'md' ? 'col-sm-5' : 'col-sm-6'"
							class="col-12">
							<button
								v-clipboard="replicatedSite"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mt-2' : 'float-right'"
								:style="['xs', 'sm', 'md'].includes(windowWidth) ? 'width: 85%;' : ''"
								class="btn bg-success"
								@click="copied = true">
								<i class="fa fa-link mr-1" />{{ translate('copy_referral_link') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import { distributor } from '@/settings/Roles';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ReplicatedLink',
	messages: [DashboardTranslations],
	mixins: [WindowSizes],
	props: {
		link: {
			type: String,
			required: true,
		},
		loading: {
			type: Boolean,
			default: true,
		},
		darkTheme: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			copied: false,
			distributor,
		};
	},
	computed: {
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		replicatedSite() {
			try {
				return this.link;
			} catch (error) {
				return '#';
			}
		},
	},
	watch: {
		copied(value) {
			if (value) {
				setTimeout(() => { this.copied = false; }, 4000);
			}
		},
	},
	mounted() {
	},
};
</script>
