<template>
	<div class="card mb-0 h-100">
		<div class="card-body">
			<div class="row">
				<div
					v-for="(item, index) in data"
					:key="index"
					:class="(index % 3) === 0 ? 'col-xl-6' : 'col-xl-3'"
					class="col-12">
					<div class="row text-primary text-center font-italic">
						<div
							v-if="windowWidth !== 'xl' || (index % 3) === 0"
							class="col" />
						<div class="col-12">
							<template v-if="windowWidth === 'xl' || windowWidth === 'xs'">
								<div
									:style="(windowWidth !== 'xs' && index === 0) ? 'margin-left:50%' : ''"
									class="text-capitalize text-gray-dark">
									{{ translate('week_number', {number: item.attributes.week_number}) }}
								</div>
								<div :style="(windowWidth !== 'xs' && index === 0) ? 'margin-left:50%' : ''">
									{{ `${$moment(item.attributes.start_date.date).format(dateFormat)} ` }} {{ translate('to') }} {{ `${ $moment(item.attributes.end_date.date).format(dateFormat)}` }}
								</div>
							</template>
							<template v-else>
								<div class="text-capitalize">
									{{ translate('week_number', {number: item.attributes.week_number}) }}
									{{ `${$moment(item.attributes.start_date.date).format(dateFormat)} ` }} {{ translate('to') }} {{ `${ $moment(item.attributes.end_date.date).format(dateFormat)}` }}
								</div>
							</template>
						</div>
					</div>
					<!-- <div class="row mb-3">
						<template v-if="windowWidth !== 'xl'">
							<div
								class="col-12 col-sm-3 text-left pt-4">
								<strong>{{ translate('estimated_earning') }}</strong>
							</div>
							<div
								class="col-12 col-sm-9 text-center p-3">
								<div class="bg-primary rounded p-2 h5">
									{{ item.attributes.estimated_earnings }}
								</div>
							</div>
						</template>
						<template v-else>
							<div
								v-if="index == 0"
								class="col-6 text-left pt-4">
								<strong>{{ translate('estimated_earning') }}</strong>
							</div>
							<div
								v-if="index == 0"
								class="col-6 text-center py-3 pr-3 pl-0">
								<div class="bg-primary rounded p-2 h5">
									{{ item.attributes.estimated_earnings }}
								</div>
							</div>
							<div
								v-if="index == 1"
								class="col-12 text-center p-3">
								<div class="bg-primary rounded p-2 h5">
									{{ item.attributes.estimated_earnings }}
								</div>
							</div>
							<div
								v-if="index == 2"
								class="col-12 text-center p-3">
								<div class="bg-primary rounded p-2 h5">
									{{ item.attributes.estimated_earnings }}
								</div>
							</div>
						</template>
					</div> -->
					<div class="table-responsive mt-3">
						<table class="table table-striped text-center">
							<thead>
								<tr>
									<th
										v-if="windowWidth !== 'xl' || (index % 3) === 0"
										:style="`${windowWidth !== 'xl' ? 'width:26.1%;' : ''}${(windowWidth !== 'xl' || (index % 3) === 0) ? 'background:white': ''}`"
										class="border-0" />
									<th
										:style="(windowWidth !== 'xl' || (index % 3) === 0) ? 'border-radius: 0.25rem 0px 0px 0px' : ''"
										class="border-top-0">
										{{ firstColLabel }}
									</th>
									<th class="border-top-0">
										{{ secondColLabel }}
									</th>
									<th class="border-top-0">
										{{ thirdColLabel }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										v-if="windowWidth !== 'xl' || (index % 3) === 0"
										:style="windowWidth !== 'xl' ? 'width:26.1%' : ''"
										class="bg-white border-0 text-left">
										<span class="font-weight-bold">{{ firstRowLabel }}</span>
									</td>
									<td class="align-middle">
										{{ item.attributes.left.bonus_volume }}
									</td>
									<td class="align-middle ">
										{{ item.attributes.right.bonus_volume }}
									</td>
									<td class="align-middle">
										{{ item.attributes.total.bonus_volume }}
									</td>
								</tr>
								<tr>
									<td
										v-if="windowWidth !== 'xl' || (index % 3) === 0"
										:style="windowWidth !== 'xl' ? 'width:26.1%' : ''"
										class="bg-white border-0 text-left">
										<span class="font-weight-bold">{{ secondRowLabel }}</span>
									</td>
									<td class="align-middle">
										{{ item.attributes.left.new_distributors }}
									</td>
									<td class="align-middle ">
										{{ item.attributes.right.new_distributors }}
									</td>
									<td class="align-middle">
										{{ item.attributes.total.new_distributors }}
									</td>
								</tr>
								<tr>
									<td
										v-if="windowWidth !== 'xl' || (index % 3) === 0"
										:style="windowWidth !== 'xl' ? 'width:26.1%' : ''"
										class="bg-white border-0 text-left">
										<span class="font-weight-bold">{{ thirdRowLabel }}</span>
									</td>
									<td class="align-middle">
										{{ item.attributes.left.personally_sponsored }}
									</td>
									<td class="align-middle ">
										{{ item.attributes.right.personally_sponsored }}
									</td>
									<td class="align-middle">
										{{ item.attributes.total.personally_sponsored }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<hr v-if="windowWidth !== 'xl'">
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard as DashboardTranslations } from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';

export default {
	name: 'TableWidget3x3',
	messages: [DashboardTranslations],
	mixins: [WindowSizes],
	props: {
		data: {
			type: Array,
			default() {
				return [];
			},
		},
		firstRowLabel: {
			type: String,
			default: '',
		},
		secondRowLabel: {
			type: String,
			default: '',
		},
		thirdRowLabel: {
			type: String,
			default: '',
		},
		firstColLabel: {
			type: String,
			default: '',
		},
		secondColLabel: {
			type: String,
			default: '',
		},
		thirdColLabel: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			dateFormat: YMD_FORMAT,
		};
	},
};
</script>
