import {
	GET_ANNOUNCEMENT, GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_LIST, HIDE_ANNOUNCEMENT,
	CREATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT, REMOVE_ANNOUNCEMENT,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Announcement {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getAnnouncements(options) {
		const { method, endpoint } = GET_ANNOUNCEMENTS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAnnouncementsList(userId, options) {
		const { method, endpoint } = GET_ANNOUNCEMENTS_LIST;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	hideAnnouncement(userId, announcementId) {
		const { method, endpoint } = HIDE_ANNOUNCEMENT;
		return this.data[method](endpoint(userId, announcementId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	loadAnnouncement(announcementId) {
		const { method, endpoint } = GET_ANNOUNCEMENT;
		return this.data[method](endpoint(announcementId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createAnnouncement(payload) {
		const { method, endpoint } = CREATE_ANNOUNCEMENT;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAnnouncement(announcementId, payload) {
		const { method, endpoint } = UPDATE_ANNOUNCEMENT;
		return this.data[method](endpoint(announcementId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteAnnouncement(id, payload) {
		const { method, endpoint } = REMOVE_ANNOUNCEMENT;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Announcement;
