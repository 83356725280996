import {
	GET_BANNER,
	GET_BANNERS,
	LIST_BANNERS,
	CREATE_BANNER,
	UPDATE_BANNER,
	REMOVE_BANNER,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

const axios = new Req();
class Banner {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
		this.pagination = this.data.pagination;
	}

	listBanners(userId, options = {}) {
		const { method, endpoint } = LIST_BANNERS;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(userId, queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBanner(bannerId) {
		const { method, endpoint } = GET_BANNER;
		return this.data[method](endpoint(bannerId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBanners(options) {
		const { endpoint, method } = GET_BANNERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createBanner(options) {
		const { endpoint, method } = CREATE_BANNER;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			this.pagination = axios.pagination;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	updateBanner(bannerId, options) {
		const { method } = UPDATE_BANNER;
		const endpoint = UPDATE_BANNER.endpoint(bannerId);
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	deleteBanner(id, payload) {
		const { method, endpoint } = REMOVE_BANNER;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Banner;
