<template>
	<div class="w-100 h-100">
		<div
			class="w-100 h-100 pb-3">
			<b-row class="d-flex align-items-center h-100">
				<b-col
					:class="['xs', 'sm'].includes(windowWidth) ? 'mt-2' : ''"
					class="col-12 col-md-12 text-center">
					<div class="h4 text-center">
						{{ translate ('retirement_countdown') }}
					</div>
					<div class="d-flex justify-content-center">
						<div class="bg-info mx-1 p-1 big-countdown ">
							<h1
								class="mt-2"
								style="font-size: 2rem">
								{{ retirementCountdown.years }}
							</h1>
							<h6
								class="text-uppercase"
								style="margin-top: -7px; font-size:large">
								{{ translate ('years') }}
							</h6>
						</div>
						<div class="bg-info mx-0 p-1 big-countdown">
							<h1
								class="mt-2"
								style="font-size: 2rem">
								{{ retirementCountdown.months }}
							</h1>
							<h6
								class="text-uppercase"
								style="margin-top: -7px; font-size:large">
								{{ translate ('months') }}
							</h6>
						</div>
						<div
							class="bg-info mx-1 p-1 big-countdown">
							<h1
								class="mt-2"
								style="font-size: 2rem">
								{{ retirementCountdown.days }}
							</h1>
							<h6
								class="text-uppercase"
								style="margin-top: -7px; font-size:large">
								{{ translate ('days') }}
							</h6>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'RetirementCountdown',
	messages: [DashboardTranslations],
	mixins: [WindowSizes],
	props: {
		retirementCountdown: {
			type: Object,
			default() {
				return {};
			},
		},
	},
};
</script>
<style scoped>
.big-countdown {
	border-radius:10px;
	width:100px;
	height: 90px;
}
.big-countdown-text {
	margin-top: -7px;
	font-size:large
}
</style>
