<template>
	<div class="row col-12 col-md-12 no-gutters justify-content-center">
		<!-- With retail profit bonus -->
		<div
			class="py-2">
			<div
				v-b-tooltip.hover.center
				:title="translate('current_progress_info')"
				class="d-inline">
				<div class="row justify-content-center px-3 h6">
					<b>{{ translate('estimated_amount') }}</b>: *
				</div>
			</div>
			<div
				v-if="!loading"
				class="row justify-content-center px-3 mb-2">
				<b><span class="text-primary h1">{{ estimatedAmount }}</span></b>
			</div>
			<hr class="my-2 py-1">
		</div>
		<b-row class="col-12">
			<b-col class="col-lg-6">
				<div
					:class="'text-muted font-weight-bold'"
					class="d-flex justify-content-center">
					{{ translate('tuun_orders_count') }}
				</div>
				<div
					v-if="!loading"
					class="text-muted text-truncate d-flex justify-content-center">
					{{ ordersQty }}
				</div>
			</b-col>
			<b-col class="col-lg-6">
				<div
					:class="'text-muted font-weight-bold'"
					class="d-flex justify-content-center">
					{{ translate('total_paid') }}
				</div>
				<div
					v-if="!loading"
					class="text-muted text-truncate d-flex justify-content-center">
					{{ totalPaid }}
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import {
	Dashboard as DashboardMessages,
	Tooltip,
	Promotions as PromoMessages,
} from '@/translations';
import Dashboard from '@/util/Dashboard';

export default {
	name: 'RetailProfitBonus',
	messages: [DashboardMessages, Tooltip, PromoMessages],
	props: {
		userId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			retailProfitBonus: new Dashboard(),
		};
	},
	computed: {
		loading() {
			return !!this.retailProfitBonus.data.loading;
		},
		estimatedAmount() {
			try {
				return this.retailProfitBonus.data.response.data.response.estimated_amount.formatted;
			} catch (error) {
				return {};
			}
		},
		ordersQty() {
			try {
				return this.retailProfitBonus.data.response.data.response.orders_count;
			} catch (error) {
				return {};
			}
		},
		totalPaid() {
			try {
				return this.retailProfitBonus.data.response.data.response.total_paid.formatted;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		loading(newValue) {
			this.$emit('changeLoading', newValue);
		},
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			this.retailProfitBonus.getRetailProfitBonus(this.userId);
		},
	},
};
</script>
