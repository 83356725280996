<template>
	<div class="row no-gutters justify-content-center">
		<div class="col-12 col-md-12">
			<div>
				<!-- With e-commerce promo -->
				<div
					class="py-2">
					<div
						v-b-tooltip.hover.center
						:title="translate('current_progress_info')"
						class="d-inline">
						<div class="row justify-content-center px-3 h5">
							<b>{{ translate('estimated_amount') }}</b>: *
						</div>
					</div>
					<div
						v-if="!loading"
						class="row justify-content-center px-3 mb-2">
						<b><span class="text-primary h1">{{ estimatedAmount }}</span></b>
					</div>
					<div
						class="table-responsive">
						<table class="table table-hover">
							<thead class="table-head">
								<tr>
									<th class="border-top-0 text-center">
										{{ translate('boxes') }}
									</th>
									<th class="border-top-0 text-center">
										{{ translate('orders_qty') }}
									</th>
									<th class="border-top-0 text-right">
										{{ translate('earned') }}
									</th>
									<th class="border-top-0 text-right">
										{{ translate('subtotal_usd') }}
									</th>
								</tr>
							</thead>
							<tbody v-if="!loading && hasData">
								<tr
									v-for="(item, index) in data"
									:key="index">
									<td class="text-center align-middle">
										<img
											:src="require(`@/assets/images/themes/${themeName}/promotions/${getColorBox(item)}_box.png`)"
											alt="">
										<b>{{ '\tx' + item.boxes }}</b>
									</td>
									<td class="text-center align-middle">
										<orders
											v-if="item.orders.length > 0"
											:id="`${index}-order-widget`"
											:order-id="index"
											:orders="item.orders" />
										<span v-else>
											{{ item.orders.length }}
										</span>
									</td>
									<td class="text-right align-middle">
										<b>{{ item.boxes }}</b> {{ ' x ' + translate('amount', { amount: item.earned_formated }) }}
									</td>
									<td class="text-right align-middle">
										{{ translate('amount', { amount: item.subtotal_formatted }) }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- Without Customers with V-Fill orders -->
					<is-loading
						v-if="!hasData"
						:loading-label="translate('loading')"
						:no-data-label="translate('v_fill_orders_not_found')"
						:loading="loading"
						:has-data="hasData" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	Dashboard as DashboardMessages,
	Ranks,
	Tooltip,
	AccountInformation,
	Promotions as PromoMessages,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { RISING_STAR_DETAILS_URL } from '@/settings/Ranks';
import Dashboard from '@/util/Dashboard';
import Orders from './Orders';

export default {
	name: 'EcommerceDetails',
	messages: [DashboardMessages, Ranks, Tooltip, AccountInformation, PromoMessages],
	components: {
		Orders,
	},
	mixins: [WindowSizes],
	props: {
		userId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			infoLink: RISING_STAR_DETAILS_URL,
			themeName: process.env.VUE_APP_THEME,
			eCommercePromo: new Dashboard(),
		};
	},
	computed: {
		data() {
			try {
				return this.eCommercePromo.data.response.data.response.orders;
			} catch (error) {
				return {};
			}
		},
		loading() {
			return !!this.eCommercePromo.data.loading;
		},
		hasData() {
			return !!this.data.length;
		},
		estimatedAmount() {
			try {
				return this.eCommercePromo.data.response.data.response.totals.formatted_amount;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		loading(newValue) {
			this.$emit('changeLoading', newValue);
		},
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			this.eCommercePromo.getEcommercePromotion(this.userId);
		},
		getColorBox(item) {
			return item.earned > 0 ? 'green' : 'gray';
		},
	},
};
</script>
<style scoped>
.table-responsive {
	max-width: 90%;
	margin: auto;
	max-height: 300px;
}
table thead tr {
	background: white;
	color: black;
}
th {
	padding-left: 6px;
	padding-right: 6px;
}
td {
	padding-left: 6px;
	padding-right: 6px;
}
.table-responsive::-webkit-scrollbar {
	-webkit-appearance: none;
}
.table-responsive::-webkit-scrollbar:vertical {
	width: 10px;
}
.table-responsive::-webkit-scrollbar:horizontal {
	height: 0px;
}
.table-responsive::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, .5);
	border-radius: 10px;
	border: 2px solid #ffffff;
}
.table-responsive::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #ffffff;
}
</style>
