<template>
	<div class="col-12">
		<hr class="m-2">
		<b-row
			class="no-gutters mx-3 my-2"
			:class="{
				'align-items-center': centered,
			}">
			<b-col class="col-2">
				<img
					:src="require(`@/assets/images/themes/${themeName}/qualified/${completed ? 'check_positive' : 'check_negative'}.png`)"
					alt="">
			</b-col>
			<b-col class="col-10">
				<slot name="milestone-data">
					{{ translate('active') }}
				</slot>
			</b-col>
		</b-row>
	</div>
</template>
<script>
export default {
	name: 'ReferralBonusMilestone',
	props: {
		completed: {
			type: Boolean,
			required: false,
		},
		centered: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
		};
	},
};
</script>
