<!-- eslint-disable vue/no-v-html -->
<template>
	<div v-if="requestedUser">
		<popups />
		<div class="row mb-sm-0">
			<div class="col-12 col-md-6 col-lg-auto">
				<div class="h4 d-inline-block">
					<span class="d-none d-md-inline-block">{{ translate('welcome') }}</span> {{ requestedUser.fullname }}!
					<span>({{ translate('customer_id') }}: </span><span class="font-weight-bold">{{ requestedUser.id }}</span>)
				</div>
			</div>
		</div>
		<b-row class="pt-2">
			<b-col
				class="mb-2 h-100"
				cols="12"
				:md="!freeProductsPromotionsLoading && Object.keys(freeProductsPromotions).length === 0 ? '12' : '6'">
				<autoship
					v-if="!isJns() && !loading && hasData"
					:next-autoship-date="data.next_autoship_date"
					:dark-theme="true" />
				<link-widget
					v-if="!loading && hasData"
					class="my-1"
					:title="translate('replicated_site')"
					:link="data.replicated_site.url" />
				<link-widget
					v-if="!loading && hasData"
					:title="translate('become_customer_link')"
					:description="translate('become_customer_link_description')"
					:link="data.other_links.become_a_customer" />
				<is-loading
					v-if="loading || !hasData"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					class="mx-auto" />
			</b-col>
			<b-col
				class="mb-2"
				cols="12"
				md="6">
				<free-products-promotions
					v-if="!freeProductsPromotionsLoading && Object.keys(freeProductsPromotions).length > 0"
					:promotions="freeProductsPromotions" />
				<is-loading
					v-if="freeProductsPromotionsLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="freeProductsPromotionsLoading"
					class="mx-auto" />
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col class="mb-1">
				<b-card
					v-if="hasSponsorData && !requestedUser.no_sponsor"
					class="h-100 border-top-darken">
					<template>
						<b-row>
							<b-col>
								<b><p class="h5 d-inline-block">{{ translate('sponsor_information') }}</p></b>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="col-12 col-md-2 col-2 mt-2 mb-2">
								<p class="mb-0 p-0 text-left">
									{{ accountData.sponsor }}
								</p>
								<p class="mb-0">
									<b>{{ translate('username') }}</b>
								</p>
							</b-col>
							<b-col class="col-12 col-md-2 col-2 mt-2 mb-2">
								<p class="mb-0 p-0 text-left">
									{{ accountData.sponsor_name }}
								</p>
								<p class="mb-0">
									<b>{{ translate('name') }}</b>
								</p>
							</b-col>
							<b-col class="col-12 col-md-3 col-3 mt-2 mb-2">
								<p class="mb-0 p-0 text-left text-muted">
									<a
										:href="'mailto:' + accountData.sponsor_email"
										target="_blank">{{ accountData.sponsor_email }}</a>
								</p>
								<p class="mb-0">
									<b>{{ translate('email') }}</b>
								</p>
							</b-col>
							<b-col class="col-12 col-md-5 col-5 mt-2 mb-2">
								<p class="mb-0 p-0 text-left text-muted">
									<a
										:href="accountData.sponsor_replicated_site"
										target="_blank">{{ accountData.sponsor_replicated_site }}</a>
								</p>
								<p class="mb-0">
									<b>{{ translate('replicated_site') }}</b>
								</p>
							</b-col>
						</b-row>
					</template>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Popups from '@/components/Popups';
import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard as messages, Grids } from '@/translations';
import AccountInformation from '@/util/Dashboard';
import SponsorInformation from '@/util/Profile';
import Autoship from './Autoship';
import LinkWidget from '@/views/Dashboard/components/LinkWidget';
import User from '@/util/User';
import FreeProductsPromotions from '@/views/Dashboard/components/FreeProductsPromotions';
import Promotions from '@/util/Promotions';

export default {
	name: 'CustomerDashboard',
	components: {
		FreeProductsPromotions,
		Autoship,
		Popups,
		LinkWidget,
	},
	messages: [messages, Grids],
	mixins: [WindowSizes],
	data() {
		return {
			userUtil: new User(),
			accountInformation: new AccountInformation(),
			sponsorInformation: new SponsorInformation(),
			requestedUser: null,
			promotionsInfo: new Promotions(),
		};
	},
	computed: {
		data() {
			try {
				const { response } = this.accountInformation.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			const response = Object.keys(this.data).length;
			return !!response;
		},
		loading() {
			return !!this.accountInformation.data.loading;
		},
		response() {
			try {
				const { data } = this.sponsorInformation.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		accountData() {
			try {
				return this.response.data.attributes;
			} catch (error) {
				return {};
			}
		},
		hasSponsorData() {
			try {
				return typeof this.accountData.sponsor !== 'undefined';
			} catch (error) {
				return false;
			}
		},
		freeProductsPromotions() {
			try {
				const { response } = this.promotionsInfo.data.response.data;
				if (Array.isArray(response) && response.length === 0) {
					return {};
				}
				return response;
			} catch (error) {
				return {};
			}
		},
		freeProductsPromotionsLoading() {
			try {
				return !!this.promotionsInfo.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		let userId = this.$user.details().id;
		// It means that a corporate user is trying to see a customer's dashboard
		if (this.$route.params.userId) {
			userId = this.$route.params.userId;
			this.userUtil.fetchUser({ user_id: userId }).then((response) => this.userData(response.response));
		} else { // It means that a customer is trying to see his own dashboard
			this.userData(this.$user.details());
		}
		this.promotionsInfo.getPromotionsAlert(userId);
	},
	methods: {
		userData(details) {
			this.requestedUser = details;
			this.accountInformation.getAccountInfoCustomer(this.requestedUser.id);
			if (!this.requestedUser.no_sponsor) {
				this.sponsorInformation.getCustomerInformation(this.requestedUser.id);
			}
		},
	},
};
</script>
