import {
	ORDERS,
	GET_GIFT_ORDERS,
	REFERRED_ORDERS,
	INVOICE,
	GET_ORDERS_DETAILS,
	GET_DOWNLOADS,
	DOWNLOAD_PRODUCT,
	GET_ORDER_REFUNDS,
	DOWNLOAD_REFUND,
	GET_ORDER_REFUND_AMOUNT,
	REFUND_ORDER,
	GET_ORDER_INFO,
	CHANGE_ORDER_PAYMENT_METHOD,
	MANUAL_ORDER,
	COMPLETE_ORDER,
	FRAUD_ORDER,
	GET_REFUND_REASONS,
	PICKUP_ORDERS,
	GET_ORDER_MULTI_PAYMENTS,
	UPLOAD_SETTLEMENT_FILE,
	GET_SETTLEMENTS,
	GET_SETTLEMENT_DETAIL,
	APPROVE_SETTLEMENT,
	REJECT_SETTLEMENT,
	MANUAL_ORDERS,
	MANUAL_ORDERS_BY_TYPE,
	GET_ORDER_DOCUMENT,
	UPDATE_ORDER_DOCUMENTS,
	PENDING_ORDERS_DOCUMENT_ALERT,
	GET_UNSHIPPED_ORDERS,
	GET_GUI_INFORMATION,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';
import Req2 from './AxiosRequestEnhanced';

const axios = new Req();

class Order {
	constructor() {
		this.data = new Req(apiFilters);
		this.data2 = new Req2(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getOrders(options) {
		const { method, endpoint } = ORDERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getInvoice(orderId) {
		const { method, endpoint } = INVOICE;

		this.options.url = endpoint(orderId);
		this.options.method = method;

		return this.data.getBlobData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadRefund(refundId) {
		const { method, endpoint } = DOWNLOAD_REFUND;

		this.options.url = endpoint(refundId);
		this.options.method = method;

		return this.data.getBlobData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReferredOrders(userId, options) {
		const { method, endpoint } = REFERRED_ORDERS;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPickupOrders(options) {
		const { method, endpoint } = PICKUP_ORDERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getManualOrders(options) {
		const { method, endpoint } = MANUAL_ORDERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getManualOrderTypes(options) {
		const { method, endpoint } = MANUAL_ORDERS_BY_TYPE;
		return this.data2[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDetails(orderId) {
		const { method, endpoint } = GET_ORDERS_DETAILS;
		return this.data[method](endpoint(orderId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDocument(orderId, side) {
		const { method, endpoint } = GET_ORDER_DOCUMENT;
		return this.data.getBlobData({ method, url: endpoint(orderId, side) }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateOrderDocument(orderId, payload) {
		const { method, endpoint } = UPDATE_ORDER_DOCUMENTS;

		this.options.data = payload;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint(orderId);
		this.options.method = method;

		return this.data.postFormData(this.options).then((response) => response).catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
	}

	getInfo(orderId) {
		const { method, endpoint } = GET_ORDER_INFO;
		return this.data[method](endpoint(orderId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDownloads(userId, options) {
		const { method, endpoint } = GET_DOWNLOADS;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	changePaymentMethod(orderId, payload) {
		const { method, endpoint } = CHANGE_ORDER_PAYMENT_METHOD;
		return this.data[method](endpoint(orderId), payload);
	}

	refund(orderId, payload) {
		const { method, endpoint } = REFUND_ORDER;
		return this.data[method](endpoint(orderId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	complete(orderId, payload) {
		const { method, endpoint } = COMPLETE_ORDER;
		return this.data[method](endpoint(orderId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	fraud(orderId, payload) {
		const { method, endpoint } = FRAUD_ORDER;
		return this.data[method](endpoint(orderId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRefunds(orderId, options) {
		const { method, endpoint } = GET_ORDER_REFUNDS;
		return this.data[method](endpoint(orderId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRefundAmount(orderId, payload) {
		const { method, endpoint } = GET_ORDER_REFUND_AMOUNT;
		return this.data[method](endpoint(orderId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getMultiPayments(userId) {
		const { method, endpoint } = GET_ORDER_MULTI_PAYMENTS;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	download(id) {
		const { method, endpoint } = DOWNLOAD_PRODUCT;

		this.options.url = endpoint(id);
		this.options.method = method;

		return this.data.getBlobData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	manualOrder(payload) {
		const { method, endpoint } = MANUAL_ORDER;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	refundReason() {
		const { method, endpoint } = GET_REFUND_REASONS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	uploadSettlement(options) {
		const { method, endpoint } = UPLOAD_SETTLEMENT_FILE;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return axios.postFormData(this.options).then((response) => {
			this.data = response;
			this.pagination = axios.pagination;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});
	}

	getSettlements() {
		const { method, endpoint } = GET_SETTLEMENTS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSettlementDetails(settlementId) {
		const { method, endpoint } = GET_SETTLEMENT_DETAIL;
		return this.data[method](endpoint(settlementId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	approveSettlement(settlementId, payload) {
		const { method, endpoint } = APPROVE_SETTLEMENT;
		return this.data[method](endpoint(settlementId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	rejectSettlement(settlementId) {
		const { method, endpoint } = REJECT_SETTLEMENT;
		return this.data[method](endpoint(settlementId));
	}

	getGiftOrders(options) {
		const { method, endpoint } = GET_GIFT_ORDERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOrderDocumentAlert() {
		const { method, endpoint } = PENDING_ORDERS_DOCUMENT_ALERT;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getUnshippedOrders(download = false, options = {}) {
		const queryParams = Req.setFilters(options).slice(1);
		const { method, endpoint } = GET_UNSHIPPED_ORDERS;
		if (download) {
			return this.data.getBlobData({ method, url: endpoint(1, queryParams) }).then((response) => response).catch((error) => {
				this.errors.record(error);
				return Promise.reject(error);
			});
		}

		return this.data[method](endpoint(0, queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getGuiInformation(download = false, options = {}) {
		const queryParams = Req.setFilters(options).slice(1);
		const { method, endpoint } = GET_GUI_INFORMATION;
		if (download) {
			return this.data.getBlobData({ method, url: endpoint(1, queryParams) }).then((response) => response).catch((error) => {
				this.errors.record(error);
				return Promise.reject(error);
			});
		}

		return this.data[method](endpoint(0, queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Order;
