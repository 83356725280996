export const noRank = 'no_rank';
export const star = 'star';
export const allStar = 'all_star';
export const rockStar = 'rock_star';
export const superStar = 'super_star';
export const vStar = 'v_star';

export const RANKS = [
	noRank,
	star,
	allStar,
	rockStar,
	superStar,
	vStar,
];

export const RISING_STAR_DETAILS_URL = 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_373337_event_announcements.png?v=3';

export default {};
