<template>
	<div v-if="!!salesInfoData.length || loading">
		<div class="row">
			<div class="col mx-auto">
				<div class="card mb-0">
					<div class="card-body">
						<h5>{{ translate('total_sales') }}</h5>
						<line-chart
							v-if="!loading"
							:data-import="[salesInfoData, refundsInfoData]"
							:x-axis-label="translate(type)"
							:y-axis-label="translate('sales')"
							:pop-over-label="[translate('sales'), translate('refunds')]"
							:show-month-label="type === 'months' ? true : false"
							:currency="true"
							:currency-code="defaultCountryCurrency"
							:styles="{height: '250px'}"
							class="mt-3" />
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="loading" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import LineChart from '@/components/LineChart.js';
import { Dashboard as DashboardTranslations, Report } from '@/translations';
import Dashboard from '@/util/Dashboard';
import { DEFAULT_COUNTRY_CURRENCY } from '@/settings/Country';

export default {
	name: 'IncomeTotalsGraph',
	messages: [DashboardTranslations, Report],
	components: {
		LineChart,
	},
	props: {
		userId: {
			type: [Number, String],
			default: 0,
		},
	},
	data() {
		return {
			salesInfo: new Dashboard(),
			defaultCountryCurrency: DEFAULT_COUNTRY_CURRENCY,
		};
	},
	computed: {
		salesInfoData() {
			try {
				const response = [];
				const { data } = this.salesInfo.data.response.data.response;
				data.forEach((item) => {
					const { code, year, total } = item;
					response.push({ code: code.toLowerCase(), year, total });
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		refundsInfoData() {
			try {
				const response = [];
				const { data } = this.salesInfo.data.response.data.response;
				data.forEach((item) => {
					const { code, year, refunds } = item;
					response.push({ code: code.toLowerCase(), year, total: refunds });
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		loading() {
			return !!this.salesInfo.data.loading;
		},
		type() {
			try {
				const { type } = this.salesInfo.data.response.data.response;
				return type;
			} catch (error) {
				return 'months';
			}
		},
	},
	mounted() {
		if (this.userId > 0) {
			this.salesInfo.getTotalSalesUser(this.userId);
		} else {
			this.salesInfo.getTotalSales();
		}
	},
};
</script>
