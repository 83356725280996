<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row">
		<div class="col">
			<div class="card mb-0">
				<div class="bg-dark p-3">
					<div class="row mb-3">
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'flex-column' : ''"
							class="col d-flex align-items-center justify-content-between">
							<div class="text-light text-uppercase font-weight-bold">
								{{ title }}
								<div
									v-if="description.length > 0"
									v-b-tooltip.hover.up
									:title="description"
									class="d-inline">
									<i class="fas fa-info-circle" />
								</div>
							</div>
							<copy-button
								btn-class="btn btn-success"
								:copy-content="link"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mx-auto mt-2' : 'btn-sm'"
								:style="['xs', 'sm'].includes(windowWidth) ? 'width: 85%;' : ''"
								icon-class="fa fa-link"
								:button-text="translate('copy_link')" />
						</div>
					</div>
					<div
						:class="['xs', 'sm'].includes(windowWidth) ? 'mx-auto' : ''"
						:style="['xs', 'sm'].includes(windowWidth) ? 'width: 85%;' : ''"
						class="row no-gutters">
						<div
							v-if="!['xs', 'sm'].includes(windowWidth)"
							class="col-auto pr-2">
							{{ translate('url') }}:
						</div>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'text-left'"
							class="col">
							<span v-if="['xs', 'sm'].includes(windowWidth)">
								{{ translate('url') }}:
							</span>
							<a
								:href="link"
								target="_blank"
								v-html="formattedUrl" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CopyButton from '@/components/CopyButton';
import { formatUrlWordBreaks } from '@/util/Urls';
import { Dashboard as DashboardTranslations, Profile } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'LinkWidget',
	messages: [DashboardTranslations, Profile],
	components: { CopyButton },
	mixins: [WindowSizes],
	props: {
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			default: '',
		},
		link: {
			type: String,
			required: true,
		},
	},
	computed: {
		formattedUrl() {
			return formatUrlWordBreaks(this.link);
		},
	},
};
</script>
