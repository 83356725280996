<template>
	<div class="card mb-0 h-100">
		<div class="card-header bg-primary-darken text-white border-0 py-2 px-3">
			<div class="row p-0 m-0">
				<div class="col m-0 p-0 text-left">
					<h5 class="mb-0">
						{{ headerTitle }}
					</h5>
				</div>
				<div
					v-if="headerIndicator.length > 0"
					class="col-auto m-0 p-0 text-right">
					<span class="text-right h5">{{ headerIndicator }}</span>
				</div>
			</div>
		</div>
		<div class="card-body pl-3 pr-3 pb-0 pt-3 bg-primary-medium clearfix text-right">
			<i
				v-if="!hideSmallLabel"
				:class="icon"
				class="p-0 font-5xl float-left text-white background-card-widget-icon" />
			<div
				v-if="!hideSmallLabel"
				class="text-uppercase font-weight-bold font-xs text-white">
				{{ smallLabel }}
			</div>
			<template v-if="!hideSmallLabel && parseInt(bigLabel).toString().length >= abbreviateSince && showAbbreviation">
				<div
					class="text-white"
					:class="['md', 'lg', 'xl'].includes(windowWidth) ? 'h3' : 'h2'">
					{{ bigLabel | numeral('0.00a') | capitalize }}
				</div>
				<div class="text-uppercase text-right font-sm text-white mb-2">
					{{ `* ${bigFormattedLabel}` }}
				</div>
			</template>
			<template v-else-if="abbreviationWithoutFormat">
				<div
					class="text-white"
					:class="['md', 'lg', 'xl'].includes(windowWidth) ? 'h3' : 'h2'">
					{{ bigLabel }}
				</div>
				<div class="text-uppercase text-right font-sm text-white mb-2">
					{{ `* ${bigFormattedLabel}` }}
				</div>
			</template>
			<template v-else>
				<div
					class="text-white"
					:class="bigLabelClass">
					{{ bigFormattedLabel }}
				</div>
				<div
					v-if="hideSmallLabel"
					class="text-right text-small text-white mb-2">
					{{ bigLabelDescription }}
				</div>
			</template>
		</div>
		<div
			v-if="optionalLabel != ''"
			v-b-tooltip.hover.top
			:class="tooltipLabel ? 'pointer': ''"
			:title="tooltipLabel"
			class="card-footer bg-primary-soft border-0 py-1 text-right text-white font-xs">
			{{ optionalLabel }}
		</div>
		<div
			v-else
			class="card-footer bg-primary-soft border-0 py-1 text-right text-white">
			&nbsp;
		</div>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'CardWidgetRegular',
	mixins: [WindowSizes],
	props: {
		headerTitle: {
			type: String,
			default: '',
		},
		headerIndicator: {
			type: String,
			default: '',
		},
		smallLabel: {
			type: String,
			default: '',
		},
		bigLabel: {
			type: String,
			default: '',
		},
		bigFormattedLabel: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		optionalLabel: {
			type: String,
			default: null,
		},
		tooltipLabel: {
			type: String,
			default: null,
		},
		showAbbreviation: {
			type: Boolean,
			default: false,
		},
		abbreviateSince: {
			type: Number,
			default: 4,
		},
		bigLabelDescription: {
			type: String,
			default: null,
		},
		hideSmallLabel: {
			type: Boolean,
			default: false,
		},
		abbreviationWithoutFormat: {
			type: Boolean,
			default: false,
		},

	},
	computed: {
		bigLabelClass() {
			const header = ['md', 'lg', 'xl'].includes(this.windowWidth) ? 'h3' : 'h2';
			const margin = this.hideSmallLabel ? '' : 'mb-3';
			return `${header} ${margin}`;
		},
	},
};
</script>
