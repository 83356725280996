export function formatUrlWordBreaks(url) {											// Taken from https://css-tricks.com/better-line-breaks-for-long-urls/
	const doubleSlash = url.split('//'); 							// Split the URL into an array to distinguish double slashes from single slashes

	const formatted = doubleSlash.map((str) => { 					// Format the strings on either side of double slashes separately
		const result = str.replace(/(?<after>:)/giu, '$1<wbr>') 	// Insert a word break opportunity after a colon
			.replace(/(?<before>[/~.,\-_?#%])/giu, '<wbr>$1') 		// Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
			.replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>'); // Before and after an equals sign or ampersand
		return result;
	}).join('//<wbr>'); 											// Reconnect the strings with word break opportunities after double slashes

	return formatted;
}

export default {};
