<template>
	<b-modal
		id="video-modal"
		v-model="showModal"
		body-class="pt-0"
		centered
		hide-header
		hide-footer
		no-close-on-escl
		no-close-on-backdrop
		size="lg">
		<div class="text-right my-2">
			<span
				class="fa fa-times text-muted pointer"
				@click="nextPopup" />
		</div>
		<vue-vimeo-player
			v-if="currentPopup.media_type === 'video'"
			ref="player"
			:options="playerOptions"
			:video-url="currentPopup.media_url"
			autoplay />
		<!-- Popup with "media" type not implemented -->
		<b-row
			v-if="!currentPopup.show_only_once"
			class="mt-2">
			<b-col class="md text-center">
				<div class="form-check mt-1">
					<input
						id="dont_show_again"
						v-model="dontShowThis"
						name="dont_show_again"
						class="form-check-input"
						type="checkbox">
					<label
						for="dont_show_again"
						class="label">
						{{ translate('dont_show_this_again') }}
					</label>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>
<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { admin } from '@/settings/Roles';
import { Popups } from '@/translations';
import Popup from '@/util/Popup';

export default {
	name: 'MediaPopups',
	messages: [Popups],
	components: {
		vueVimeoPlayer,
	},
	data() {
		return {
			admin,
			alert: new this.$Alert(),
			currentPopup: {},
			showModal: false,
			queueIndex: null,
			queueLength: null,
			showPopupsUtil: new Popup(),
			hidePopupsUtil: new Popup(),
			dontShowThis: false,
			queue: [],
			playerOptions: {
				responsive: true,
				speed: false,
			},
		};
	},
	computed: {
		userId() {
			return this.$user.details().id;
		},
	},
	watch: {
		queueIndex(value) {
			// If user already saw last media popup
			if (this.queueIndex >= this.queueLength) {
				this.$emit('done');
			}

			this.currentPopup = this.queue[value];
			this.showModal = true;
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			if (!this.admin.includes(this.$user.details().type)) {
				this.showPopupsUtil.getMediaPopups(this.userId).then((response) => {
					this.queue = response.map((popup) => ({
						id: popup.id,
						code_name: popup.attributes.code_name,
						media_type: popup.attributes.media_type,
						media_url: popup.attributes.media_url,
						show_only_once: popup.attributes.show_only_once,
					}));
					this.queueIndex = 0;
					this.queueLength = this.queue.length;
					this.showPopups();
				});
			} else {
				this.$emit('done');
			}
		},
		showPopups() {
			if (this.queueLength === 0) {
				this.$emit('done');
			}
		},
		nextPopup() {
			if (this.dontShowThis) {
				const trans = {
					title: this.translate(`${this.currentPopup.code_name}_popup_alert_title`),
					text: this.translate(`${this.currentPopup.code_name}_popup_alert_text`),
				};

				const options = {
					config: {
						icon: 'info',
						confirmButtonText: this.translate('ok'),
						allowOutsideClick: false,
						allowEscapeKey: false,
						confirmButtonColor: '#eb772f',
					},
				};

				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					this.hidePopupsUtil.hidePopup(this.userId, this.currentPopup.id);
					this.dontShowThis = false;
					this.showModal = false;
					this.queueIndex += 1;
				});
			} else {
				this.showModal = false;
				this.queueIndex += 1;
			}
		},
	},
};
</script>
<style>
.popup-input span {
	font-size: 0.75em;
}
</style>
